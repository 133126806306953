import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import moment from 'moment'
import { Link } from 'react-router-dom';
import { AppContext } from '../../utils/ContextProvider';
import SlideToggle from '../SlideToggle';

const AssetActuator = (props) => {

    const [showFilters, setShowFilters] = useState(false)
    const positionDict = { 0: "Closed", 1: "Half", 2: "Partial", 3: "Open" }
    const statusColorDict = { 0: "#B53838", 1: "#DCB052", 3: "#46775A" }
    const severityDict = { '0': "Info", "1": "Caution", "2": "Critical" }
    const context = useContext(AppContext)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);

    return (
        <div>
            <div className='device tablet:p-8 mobile:p-3'>
                <div className='device'>
                    <div className=''>
                        <div className='tablet:p-8 mobile:p-3'>
                            <div className='input-border pb-8 items-center mb-6'>
                                <div className='flex flex-wrap w-full items-center justify-between'>
                                    <input
                                        placeholder='Actuator Name'
                                        className='p-1 text-3xl p-2 outline-none hide-input-background laptop:w-3/12 tablet:w-full mobile:w-full'
                                        type="text"
                                        name='name'
                                        disabled={true}
                                        defaultValue={props.actuator.name ? props.actuator.name : ""} />
                                    {/* <div className='w-3/12'>
                                        <label className='w-1/3 text-base input-label-color' htmlFor="id">Actuator ID</label>
                                        <input
                                            placeholder='Actuator ID'
                                            className='w-full text-base input-color outline-none hide-input-background'
                                            type="text"
                                            name="id"
                                            value={props.actuator.id ? props.actuator.id : ""}
                                            disabled={true} />
                                    </div>
                                    <div className='w-3/12'>
                                        <label className='w-1/3 text-base input-label-color' htmlFor="device_id">Linked Device</label>
                                        <input
                                            placeholder='Actuator ID'
                                            className=' w-full text-base input-color outline-none hide-input-background'
                                            type="text"
                                            name="id"
                                            value={props.actuator.device_id ? props.actuator.device_id : ""}
                                            disabled={true} />
                                    </div> */}
                                    <div className="flex flex-nowrap items-center justify-between">
                                        <div className="flex flex-nowrap items-center justify-center mr-4">
                                            Auto-Enabled
                                            <SlideToggle disabled={true} setOptions={null} options={props.actuator} id={"auto_enabled"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-nowrap items-start justify-between w-full px-4 input-border">
                                <div className='w-1/2'>
                                    <p className='font-medium'>Notes</p>
                                    <div className='flex flex-wrap justify-center items-center'>
                                        <div className='flex flex-wrap w-full justify-center items-center pt-3 pb-3 mb-6'>
                                            <div className='w-full flex flex-wrap items-center'>
                                                <textarea
                                                    className='p-1 w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                    name='notes'
                                                    value={props.actuator.notes}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <p className='font-medium'>Description</p>
                                    <div className='flex flex-wrap justify-center items-center'>
                                        <div className='flex flex-wrap w-full justify-center items-center pt-3 pb-3 mb-6'>
                                            <div className='w-full flex flex-wrap items-center'>
                                                <textarea
                                                    className='p-1 w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                    name='description'
                                                    value={props.actuator.description}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='min-h-[80vh] flex flex-nowrap items-start justify-between px-4'>
                    <div className="w-1/2 pl-[30px] overflow-x-auto">
                        <p className='text-[20px] font-semibold mb-[30px]'>Command Log</p>
                        <div className='w-full min-w-[576px] h-[250px] overflow-y-auto'>
                            <div className="flex flex-nowrap items-center justify-between w-auto">
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Action Date</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Performed By</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>From</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>To</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>Status</p>
                            </div>
                            <div className="overflow-auto max-h-[350px] h-[250px]">
                                {props.actuator.logs && props.actuator.logs.map((e, ix) => (
                                    <div key={ix + "actuatorlog"} className="flex flex-nowrap items-center justify-between py-[10px] border-b border-[#B8BBB9] w-auto">
                                        <p className='text-[12px] w-[140px]'>{moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                        <p className='text-[12px] w-[140px]'>{e.created_by}</p>
                                        <p className='text-[12px] w-[80px]'>{positionDict[e.from_position]}</p>
                                        <p className='text-[12px] w-[80px]'>{positionDict[e.to_position]}</p>
                                        <p className='text-[12px] w-[80px]'>{e.status === 200 ? "Success" : "Failed"}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-1/2 pl-[30px] overflow-x-auto">
                        <p className='text-[20px] font-semibold mb-[30px]'>Alert Log</p>
                        <div className='w-full min-w-[576px] h-[250px] overflow-y-auto'>
                            <div className="flex flex-nowrap items-center justify-between w-auto">
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Alert Type</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Alert Group</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[60px]'>Severity</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[130px]'>First Triggered</p>
                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[130px]'>Last Triggered</p>
                            </div>
                            <div className="overflow-auto max-h-[350px] h-[250px]">
                                {props.actuator.alerts && props.actuator.alerts.map((e, ix) => (
                                    <div key={ix + "actuatorlog"} className={`flex flex-nowrap items-center justify-between py-[10px] border-b border-[#B8BBB9] w-auto text-[${statusColorDict[e.severity]}]`}>
                                        <p className='text-[12px] w-[140px]'>{e.type}</p>
                                        <Link to={`/alerts?building=${context.getBuilding}&group=${e.alert_group_id}`}><p className='text-[12px] w-[140px]'>{e.name}</p></Link>
                                        <p className='text-[12px] w-[60px]'>{severityDict[e.severity]}</p>
                                        <p className='text-[12px] w-[130px]'>{moment(e.first_timestamp).format('YYYY-MM-DD HH:mm:ss')}</p>
                                        <p className='text-[12px] w-[130px]'>{moment(e.last_timestamp).format('YYYY-MM-DD HH:mm:ss')}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}


export default AssetActuator;