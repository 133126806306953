import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/ContextProvider";
import GlobalSVG from "../../utils/GlobalSVG";
import LoaderWheel from "../LoaderWheel";
import API from "../../utils/API";
import Swal from "sweetalert2";


const MaintenanceSubscriberLine = (props) => {

    const context = useContext(AppContext);
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [error, setError] = useState(false)
    const [subscriber, setSubscriber] = useState()
    const days1 = [{ "0": 'MON' }, { "1": 'TUE' }, { "2": 'WED' }, { "3": 'THU' }]
    const days2 = [{ "4": 'FRI' }, { "5": 'SAT' }, { "6": 'SUN' }, { "all": "All" }]

    const updateSubscriber = (event) => {
        let tempData = { [event.target.name]: event.target.value }
        let value = event.target.value
        if (event.target.name === 'subscriber') {
            let subscriberData = props.buildingUsers.find(e => e.username === event.target.value)
            tempData.alternate_email = subscriberData.alternate_email
            setSubscriber(prev => ({ ...prev, ...tempData }))
        } else if (event.target.name === 'days_of_week') {
            let tempPeriod = subscriber[event.target.name] ? subscriber[event.target.name].split(",") : []
            if (value === 'all') {
                if (subscriber.days_of_week && subscriber.days_of_week.length > 0) {
                    value = []
                } else {
                    value = [
                        ...days1.flatMap(e => Object.keys(e)),
                        ...days2.flatMap(e => Object.keys(e))
                    ].filter(e => e !== 'all')
                }
            }
            else if (tempPeriod.includes(event.target.value)) value = tempPeriod.filter(e => e !== event.target.value)
            else {
                tempPeriod.push(event.target.value)
                value = tempPeriod
            }
            value = value.join(",")
            setSubscriber(e => ({ ...e, [event.target.name]: value }))
        }
    }

    useEffect(() => {
        if (props.creating) setEdit(true)
    }, [props.creating])

    useEffect(() => {
        if (subscriber && subscriber.subscriber) {
            if (!subscriber.alternate_email || subscriber.alternate_email.trim() === '') {
                setError({ alternate_email: "Please add an email address in Profile." })
            } else {
                setError(null)
            }
        }
    }, [subscriber])



    useEffect(() => {
        if (!props.subscriber) return
        setSubscriber(props.subscriber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subscriber])


    const handleDelete = () => {
        ;
        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateSubscriber({ deleted: true }, subscriber.id).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                    updatedGroups.subscribers = updatedGroups.subscribers.filter(e => e.id !== subscriber.id)
                    props.setGroup(updatedGroups)
                    props.setGroups(tempGroups)
                    setDeleting(false)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(false)
            }
        });
    }

    const handleSave = () => {
        ;
        setSaving(true)

        let payload = JSON.parse(JSON.stringify(subscriber))
        delete payload['alternate_email']
        if (props.creating) {
            setSaving(true)
            API.createMaintenanceSubscriber(payload).then(res => {
                setSubscriber({ maintenance_group_id: props.groupId })
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                updatedGroups.subscribers.push(res.data)
                props.setGroup(updatedGroups)
                props.setGroups(tempGroups)
                props.setCreating(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        } else {
            let allowed = ["days_of_week"]
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateMaintenanceSubscriber(payload, subscriber.id).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                let updatedSubscriber = updatedGroups.subscribers.find(e => e.id === subscriber.id)
                updatedSubscriber.days_of_week = subscriber.days_of_week
                props.setGroup(updatedGroups)
                props.setGroups(tempGroups)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        }

    }

    return (
        <div className="bg-[#ededed] rounded-md w-full p-[5px] mt-[10px]">
            {subscriber &&
                <div className="flex flex-nowrap justify-between items-center relative">
                    {props.creating ?
                        <select className="text-[14px] w-[160px]" name="subscriber" onChange={updateSubscriber} value={subscriber.subscriber}>
                            <option value=""> - </option>

                            {
                                (props.buildingUsers && context.getUserInfo) &&
                                (
                                    // context.getUserInfo.manage_others_alert_subscription ?
                                    //     props.buildingUsers.map((e, idx) => (
                                    //         <option key={idx + 'buildingusers'} value={e.username}>{e.name}</option>
                                    //     ))
                                        // :
                                        (
                                            context.getUserInfo.manage_my_alert_subscription ?
                                                <option value={context.getUserInfo.username}>{context.getUserInfo.firstname} {context.getUserInfo.lastname}</option>
                                                :
                                                null
                                        )
                                )

                            }
                        </select> :
                        <div className="text-[12px] w-[160px]">
                            {subscriber.subscriber}
                        </div>
                    }
                    <div className="w-[160px]">
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                days1.map(e => (
                                    <div key={'days1' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateSubscriber({ target: { name: 'days_of_week', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${subscriber.days_of_week && subscriber.days_of_week.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                days2.map(e => (
                                    <div key={'days2' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateSubscriber({ target: { name: 'days_of_week', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${subscriber.days_of_week && subscriber.days_of_week.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="text-[12px]  w-[160px]">
                        {error && error.alternate_email}
                    </div>
                    <div className="w-[160px]">
                        <div className="flex flex-nowrap justify-end items-center">
                            {edit &&
                                (saving ?
                                    <LoaderWheel /> :
                                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]'>
                                    {context.getUserInfo.update_buildings_and_operators &&
                                        (deleting ?
                                            <LoaderWheel /> :
                                            <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete()}>{GlobalSVG.delete()}</div>)
                                    }
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                    {context.getUserInfo.update_buildings_and_operators && GlobalSVG.edit('black')}
                                </div>
                            }
                        </div>

                    </div>
                </div>}
        </div>
    )
}

export default MaintenanceSubscriberLine