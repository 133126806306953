import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Link } from "react-router-dom";
import moment from 'moment'
import './style.css'

const AlertGroups = (props) => {

    const context = useContext(AppContext);
    const statusColor = { '0': '#0538A0', '1': '#F9941E', '2': '#F75D5F' }
    const severityDict = { '0': "Info", "1": "Caution", "2": "Critical" }
    const [deleting, setDeleting] = useState([])
    const [filters, setFilters] = useState({})

    useEffect(() => {
        let tempEquipment = []
        let tempName = []
        if (!props.groups) return
        
        props.groups.forEach(group => {
            if (!group.activities) return
            let tempChan = group.activities.map(activity => group.channel_type === 3 ? activity.device_name : activity.channel_name)
            tempEquipment = new Set([...tempEquipment, ...tempChan])
        })

        let tempNm = props.groups.map(group => group.name)
        tempName = new Set([...tempNm])

        setFilters({
            name: Array.from(tempName).sort(),
            equipment: Array.from(tempEquipment).sort(),
        })

    }, [props.groups])

    const handleSort = (field) => {
        props.setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    const handleDelete = (id) => {
        ;
        setDeleting(e => [...e, id])
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateAlertGroup({ deleted: true }, id).then(async res => {
                    props.setGroups(e => e.filter(item => item.id !== id));
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(e => e.filter(item => item !== id))
            }
        });
    }

    return (
        <div className="min-h-[200px]">
            <div className="flex flex-nowrap">
            {
                    props.filters.hasOwnProperty('active') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="active" onChange={(event) => props.setFilters(e => ({ ...e, active: event.target.value ? event.target.value : null }))}>
                            <option value=''>All</option>
                            <option value="0">Inactive</option>
                            <option value="1">Active</option>
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ active: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('severity') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="severity" onChange={(event) => props.setFilters(e => ({ ...e, severity: event.target.value ? event.target.value : null }))}>
                            <option value=''>Severity</option>
                            <option value="0">Info</option>
                            <option value="1">Caution</option>
                            <option value="2">Critical</option>
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ severity: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('status') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="status" onChange={(event) => props.setFilters(e => ({ ...e, status: event.target.value ? event.target.value : null }))}>
                            <option value=''>Status</option>
                            <option value="Set">Set</option>
                            <option value="Open">Open</option>
                            <option value="Assigned">Assigned</option>
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ status: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('alert_type_id') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="alert_type_id" onChange={(event) => props.setFilters(e => ({ ...e, alert_type_id: event.target.value ? event.target.value : null }))}>
                            <option value=''> - </option>
                            {
                                props.alertTypes && Object.keys(props.alertTypes).map((e, idx) => (
                                    <option key={e + 'alerttypes'} value={e}>{props.alertTypes[e].name}</option>
                                ))
                            }
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ alert_type_id: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('equipment') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="equipment" onChange={(event) => props.setFilters(e => ({ ...e, equipment: event.target.value ? event.target.value : null }))}>
                            <option value=''>Monitored Points</option>
                            {filters && filters.equipment.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ equipment: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('name') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="name" onChange={(event) => props.setFilters(e => ({ ...e, name: event.target.value ? event.target.value : null }))}>
                            <option value=''>Alert Group</option>
                            {filters && filters.name.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ name: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('lastTriggered') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <div className='text-[12px] min-w-[100px] font-semibold'>Triggered Range:</div>
                        <DateRangePickerComponent
                            className='p-2'
                            value={props.filters.lastTriggered}
                            onChange={(event) => props.setFilters(e => ({ ...e, lastTriggered: event.value }))} />
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ lastTriggered: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('closedAt') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <div className='text-[12px] min-w-[100px] font-semibold'>Closed Range:</div>
                        <DateRangePickerComponent
                            className='p-2'
                            value={props.filters.closedAt}
                            onChange={(event) => props.setFilters(e => ({ ...e, closedAt: event.value }))} />
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ closedAt: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
            </div>
            <div className='overflow-auto'>
                <div className='flex flex-nowrap justify-between p-4 my-2 w-full min-w-[1156px]'>
                    <div className='flex flex-nowrap justify-start items-center w-[90px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('severity')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("severity") ? 'text-[green]' : ''}`}>Severity</span>
                        {props.sorting.hasOwnProperty("severity") && props.sorting.severity === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("severity") && props.sorting.severity === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[90px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('status')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("status") ? 'text-[green]' : ''}`}>Status</span>
                        {props.sorting.hasOwnProperty("status") && props.sorting.status === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("status") && props.sorting.status === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[140px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('alert_type_id')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("alert_type_id") ? 'text-[green]' : ''}`}>Type</span>
                        {props.sorting.hasOwnProperty("alert_type_id") && props.sorting.alert_type_id === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("alert_type_id") && props.sorting.alert_type_id === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('name')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("name") ? 'text-[green]' : ''}`}>Alert Group</span>
                        {props.sorting.hasOwnProperty("name") && props.sorting.name === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("name") && props.sorting.name === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start w-[150px] text-[12px] text-[#B8BBB9] font-semibold'>
                        Monitored Points
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[120px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('lastTriggered')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("lastTriggered") ? 'text-[green]' : ''}`}>Triggered At</span>
                        {props.sorting.hasOwnProperty("lastTriggered") && props.sorting.lastTriggered === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("lastTriggered") && props.sorting.lastTriggered === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[120px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('lastTriggeredSince')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("lastTriggeredSince") ? 'text-[green]' : ''}`}>Triggered Since</span>
                        {props.sorting.hasOwnProperty("lastTriggeredSince") && props.sorting.lastTriggeredSince === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("lastTriggeredSince") && props.sorting.lastTriggeredSince === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[110px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('lastAssignedTo')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("lastAssignedTo") ? 'text-[green]' : ''}`}>Assigned To</span>
                        {props.sorting.hasOwnProperty("lastAssignedTo") && props.sorting.lastAssignedTo === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("lastAssignedTo") && props.sorting.lastAssignedTo === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start w-[75px]'></div>
                </div>
                <div className="h-[200px] overflow-auto">
                    {
                        props.filteredGroups.map((e, ix) => (
                            <div key={'activitykey' + ix} style={{ color: !e.status || e.status === 'Set' ? "#B8BBB9" : statusColor[e.severity] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full min-w-[1156px] bg-[white] rounded-md'>
                                <div className={`w-[90px] text-[12px] font-medium`}>{e.severity !== null ? severityDict[e.severity] : " - "}</div>
                                <div className={`w-[90px] text-[12px] font-medium`}>{e.status ? e.status : " - "}</div>
                                <div className={`w-[140px] text-[12px] font-medium`}>{e.type_name ? e.type_name : " - "}</div>
                                <div className={`w-[130px] text-[12px] font-medium`}>{e.name ? e.name : " - "}</div>
                                <div className={`w-[150px] text-[12px] font-medium overflow-hidden overflow-ellipsis whitespace-nowrap`}>{e.equipment ? (e.equipment).join(', ') : " - "}</div>
                                <div className={`w-[120px] text-[12px] font-medium ${e.lastTriggered ? "" : "pl-[25px]"}`}>{e.lastTriggered ? moment(e.lastTriggered).format('MMM D, YYYY h:mma') : " - "}</div>
                                <div className={`w-[120px] text-[12px] font-medium ${e.lastTriggeredSince !== null ? "" : "pl-[25px]"}`}>{e.lastTriggeredSince || e.lastTriggeredSince === 0 ? parseInt(e.lastTriggeredSince / 24) + " days, " + parseInt(e.lastTriggeredSince) % 24 + " hours" : " - "}</div>
                                <div className={`w-[110px] text-[12px] font-medium ${e.lastAssignedTo ? "" : "pl-[25px]"}`}>{e.lastAssignedTo ? e.lastAssignedTo : " - "}</div>
                                <div className="flex flex-nowrap items-center justify-between w-[75px]">
                                    {!props.portfolio && <div className="w-[25px]">{GlobalSVG.ringer((e.isSubscribed ? "#107048" : "#B8BBB9"), "18", "18")}</div>}
                                    {!props.portfolio && <Link className='cursor-pointer w-[25px]' to={`/alerts?building=${context.getBuildingInfo.building_id}&group=${e.id}`}>{GlobalSVG.edit2()}</Link>}
                                    <div className='cursor-pointer w-[25px]'>
                                        {context.getUserInfo.update_buildings_and_operators &&
                                            (deleting.includes(e.id) ?
                                                <LoaderWheel /> :
                                                <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(e.id)}>{GlobalSVG.delete()}</div>)
                                        }
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
};

export default AlertGroups;