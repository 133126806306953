import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/ContextProvider";
import GlobalSVG from "../../utils/GlobalSVG";
import LoaderWheel from "../LoaderWheel";
import API from "../../utils/API";
import Swal from "sweetalert2";


const AlertSubscriberLine = (props) => {

    const context = useContext(AppContext);
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [error, setError] = useState(false)
    const [subscriber, setSubscriber] = useState()

    const updateSubscriber = (event) => {
        let tempData = { [event.target.name]: event.target.value }
        if (event.target.name === 'subscriber') {
            let subscriberData = props.buildingUsers.find(e => e.username === event.target.value)
            tempData.alternate_email = subscriberData.alternate_email
            tempData.primary_tel = subscriberData.primary_tel
        }
        setSubscriber(prev => ({ ...prev, ...tempData }))
    }

    useEffect(() => {
        if (props.creating) setEdit(true)
    }, [props.creating])

    useEffect(() => {
        if (subscriber && subscriber.method && subscriber.subscriber) {
            switch (subscriber.method) {
                case "0":
                    if (!subscriber.alternate_email || subscriber.alternate_email.trim() === '') {
                        setError({ alternate_email: "Please add an address in Profile." })
                    } else {
                        setError(null)
                    }
                    break
                case "1":
                    if (!subscriber.primary_tel || subscriber.primary_tel.trim() === '') {
                        setError({ primary_tel: "Please add a number in Profile." })
                    } else {
                        setError(null)
                    }
                    break
                case "2":
                    let tracker = false
                    if (!subscriber.primary_tel || subscriber.primary_tel.trim() === '') {
                        tracker = true
                        setError({ primary_tel: "Please add a number in Profile." })
                    }
                    if (!subscriber.alternate_email || subscriber.alternate_email.trim() === '') {
                        tracker = true
                        setError(prev => ({ ...prev, alternate_email: "Please add an address in Profile." }))
                    }
                    if (!tracker) setError(null)
                    break
                default:
                    break
            }
        }
    }, [subscriber])



    useEffect(() => {
        if (!props.subscriber) return
        setSubscriber(props.subscriber)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subscriber])


    const handleDelete = () => {
        ;
        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateSubscriber({ deleted: true }, subscriber.id).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                    updatedGroups.subscribers = updatedGroups.subscribers.filter(e => e.id !== subscriber.id)
                    props.setGroup(updatedGroups)
                    props.setGroups(tempGroups)
                    setDeleting(false)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(false)
            }
        });
    }

    const handleSave = () => {
        ;
        setSaving(true)

        let payload = JSON.parse(JSON.stringify(subscriber))

        if (props.creating) {
            setSaving(true)
            API.createSubscriber(subscriber).then(res => {
                setSubscriber({ alert_group_id: props.groupId })
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                updatedGroups.subscribers.push(res.data)
                props.setGroup(updatedGroups)
                props.setGroups(tempGroups)
                props.setCreating(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        } else {
            let allowed = ["method", "frequency"]
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateSubscriber(payload, subscriber.id).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.find(e => e.id === props.groupId)
                let updatedSubscriber = updatedGroups.subscribers.find(e => e.id === subscriber.id)
                updatedSubscriber.method = subscriber.method
                updatedSubscriber.frequency = subscriber.frequency
                props.setGroup(updatedGroups)
                props.setGroups(tempGroups)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        }

    }

    return (
        <div className="bg-[#ededed] rounded-md w-full p-[5px] mt-[10px]">
            {subscriber &&
                <div className="flex flex-nowrap justify-between items-center relative">
                    {props.creating ?
                        <select className="text-[14px]" name="subscriber" onChange={updateSubscriber} value={subscriber.subscriber}>
                            <option value=""> - </option>

                            {
                                (props.buildingUsers && context.getUserInfo) &&
                                (
                                    context.getUserInfo.manage_others_alert_subscription ?
                                        props.buildingUsers.map((e, idx) => (
                                            <option key={idx + 'buildingusers'} value={e.username}>{e.name}</option>
                                        ))
                                        :
                                        (
                                            context.getUserInfo.manage_my_alert_subscription ?
                                                <option value={context.getUserInfo.username}>{context.getUserInfo.firstname} {context.getUserInfo.lastname}</option>
                                                :
                                                null
                                        )
                                )

                            }
                        </select> :
                        <div className="text-[12px] w-[160px]">
                            {subscriber.subscriber}
                        </div>
                    }
                    <div className="text-[12px] w-[160px]">
                        {subscriber.alternate_email}
                        {
                            error && error.alternate_email &&
                            <p className="text-[10px] text-[red] font-semibold mb-2">{error.alternate_email}</p>
                        }
                    </div>
                    <div className="text-[12px] w-[90px]">
                        {subscriber.primary_tel}
                        {
                            error && error.primary_tel &&
                            <p className="text-[10px] text-[red] font-semibold mb-2">{error.primary_tel}</p>
                        }
                    </div>
                    <div className="w-[90px]">
                        <select className="text-[12px] outline-none hide-input-background" name="method" onChange={updateSubscriber} value={subscriber.method} disabled={!edit}>
                            <option value=""> - </option>
                            <option value="0">Email</option>
                            <option value="1">SMS</option>
                            <option value="2">Email & SMS</option>
                        </select>
                    </div>
                    <div className="w-[60px]">
                        <select className="text-[12px] outline-none hide-input-background" name="frequency" onChange={updateSubscriber} value={subscriber.frequency} disabled={!edit}>
                            <option value=""> - </option>
                            <option value="0">Real Time</option>
                            <option value="1">Hourly</option>
                            <option value="2">Daily</option>
                            <option value="3">Weekly</option>
                            <option value="4">Monthly</option>
                        </select>
                    </div>
                    <div className="w-[60px]">
                        <div className="flex flex-nowrap justify-end items-center">
                            {edit &&
                                (saving ?
                                    <LoaderWheel /> :
                                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]'>
                                    {context.getUserInfo.update_buildings_and_operators &&
                                        (deleting ?
                                            <LoaderWheel /> :
                                            <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete()}>{GlobalSVG.delete()}</div>)
                                    }
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                    {context.getUserInfo.update_buildings_and_operators && GlobalSVG.edit('black')}
                                </div>
                            }
                        </div>

                    </div>
                </div>}
        </div>
    )
}

export default AlertSubscriberLine