import LoaderWheel from "../LoaderWheel"
import { useContext, useEffect, useState } from "react"
import { AppContext } from '../../utils/ContextProvider'
import API from "../../utils/API"
import Swal from 'sweetalert2'
import GlobalSVG from "../../utils/GlobalSVG"
import { Link } from "react-router-dom"
import AlertsMultipleSelect from "../AlertsMultipleSelect"
import { v4 as uuidv4 } from "uuid";
import moment from 'moment'
import CircuitParamLine from "../CircuitParamLine"

const AlertActivityLine = (props) => {
    const context = useContext(AppContext)
    const [deleting, setDeleting] = useState(false)
    const [saving, setSaving] = useState(false)
    const [activity, setActivity] = useState(null)
    const [edit, setEdit] = useState(false)
    const [equipmentList, setEquipmentList] = useState(null)
    const [viewDetails, setViewDetails] = useState(false)
    const statusColor = { '0': '#0538A0', '1': '#F9941E', '2': '#F75D5F' }
    const severityDict = { '0': 'Info', '1': 'Caution', '2': 'Critical' }
    const months1 = [{ "0": 'JAN' }, { "1": 'FEB' }, { "2": 'MAR' }, { "3": 'APR' }, { "4": 'MAY' }, { "5": 'JUN' }]
    const months2 = [{ "6": 'JUL' }, { "7": 'AUG' }, { "8": 'SEP' }, { "9": 'OCT' }, { "10": 'NOV' }, { "11": 'DEC' }, { "all": "All" }]
    const days1 = [{ "0": 'MON' }, { "1": 'TUE' }, { "2": 'WED' }, { "3": 'THU' }]
    const days2 = [{ "4": 'FRI' }, { "5": 'SAT' }, { "6": 'SUN' }, { "all": "All" }]
    const hours1 = [{ "0": '00' }, { "1": '01' }, { "2": '02' }, { "3": '02' }, { "4": '04' }, { "5": '05' }, { "6": '06' },
    { "7": '07' }, { "8": '08' }, { "9": '09' }, { "10": '10' }, { "11": '11' }]
    const hours2 = [{ "12": '12' }, { "13": '13' }, { "14": '14' }, { "15": '15' }, { "16": '16' }, { "17": '17' }, { "18": '18' },
    { "19": '19' }, { "20": '20' }, { "21": '21' }, { "22": '22' }, { "23": '23' }, { "all": "All" }]
    const listParamDict = { "All/Any": ['All', "Any"], "On/Off": ['On', "Off"] }


    useEffect(() => {
        if (!props.activity) return
        let tempAct = JSON.parse(JSON.stringify(props.activity))
        if (!tempAct.parameters || tempAct.parameters.length === 0) {
            tempAct.parameters = []
            tempAct.group_parameters.forEach(groupParam => {
                let tempObj = { ...groupParam, value: groupParam.default, alert_param_type_id: groupParam.id }
                delete tempObj.id
                if (groupParam.type === 'circuit') tempObj.tempId = uuidv4()
                tempAct.parameters.push(tempObj)
            })
        } else {
            tempAct.parameters.forEach(param => {
                if (param.type === 'circuit') {
                    try {
                        param.value = JSON.parse(param.value)
                    } catch {
                        return
                    }
                    param.tempId = uuidv4()
                }
            })
        }
        setActivity(tempAct)
        if (props.adding) setEdit(true)
    }, [props.activity, props.adding])

    useEffect(() => {
        if (!props.equipmentList || !activity) return
        if (!props.equipmentList[activity.channel_type]) return
        let filteredDict = JSON.parse(JSON.stringify(props.equipmentList[activity.channel_type]))
        if (activity.channel_type === 0) {
            const conditions = { "loss of phase power": ([k, e]) => e.poles > 1, "current unbalance": ([k, e]) => e.poles > 1, "amperage harmonic issue": ([k, e]) => e.virtual === 0 }
            const reverse_conditions = [
                {
                    alerts: ["voltage outside of range", "high building kw demand", "high building kva demand", "amperage harmonic issue", "current unbalance", "poor peak power factor"],
                    condition: ([k, e]) => e.circuit_type === 'E' && !e.virtual
                }
            ]
            if (activity.type_name && activity.type_name.toLowerCase() in conditions) {
                filteredDict = Object.fromEntries(Object.entries(filteredDict).filter(conditions[activity.type_name.toLowerCase()]))
            }
            reverse_conditions.forEach(rc => {
                if (activity.type_name && !(rc.alerts.includes(activity.type_name.toLowerCase().trim()))) {
                    filteredDict = Object.fromEntries(Object.entries(filteredDict).filter(rc.condition))
                }
            })
        }
        filteredDict = Object.fromEntries(Object.entries(filteredDict).map(([k, e]) => [k, { ...e, point_name: activity.channel_type === 3 ? e.device_name : e.channel_name }]));
        setEquipmentList(filteredDict)
    }, [props.equipmentList, activity])

    const updateActivity = (event) => {
        let value = event.target.value
        if (event.target.name === 'circuits') {
            if (event.target.value === 'all') props.setSelectedPoints(Object.keys(equipmentList).map(e => activity.channel_type === 3 ? e : parseInt(e)))
            else if (event.target.value === 'none') props.setSelectedPoints([])
            else props.setSelectedPoints(prev => prev.includes(value) ? prev.filter(e => e !== value) : [...prev, event.target.value])
            return
        } else if (['months', 'days', 'hours'].includes(event.target.name)) {
            let tempPeriod = activity[event.target.name] ? activity[event.target.name].split(",") : []
            if (value === 'all') {
                switch (event.target.name) {
                    case 'months':
                        if (activity.months && activity.months.length > 0) {
                            value = []
                        } else {
                            value = [
                                ...months1.flatMap(e => Object.keys(e)),
                                ...months2.flatMap(e => Object.keys(e))
                            ].filter(e => e !== 'all')
                        }
                        break
                    case 'days':
                        if (activity.days && activity.days.length > 0) {
                            value = []
                        } else {
                            value = [
                                ...days1.flatMap(e => Object.keys(e)),
                                ...days2.flatMap(e => Object.keys(e))
                            ].filter(e => e !== 'all')
                        }
                        break
                    case 'hours':
                        if (activity.hours && activity.hours.length > 0) {
                            value = []
                        } else {
                            value = [
                                ...hours1.flatMap(e => Object.keys(e)),
                                ...hours2.flatMap(e => Object.keys(e))
                            ].filter(e => e !== 'all')
                        }
                        break
                    default:
                        break
                }
            }
            else if (tempPeriod.includes(event.target.value)) value = tempPeriod.filter(e => e !== event.target.value)
            else {
                tempPeriod.push(event.target.value)
                value = tempPeriod
            }
            value = value.join(",")
        } else if (['schedule_type', 'frequency'].includes(event.target.name)) {
            value = parseInt(value)
        }
        setActivity(e => ({ ...e, [event.target.name]: value }))
    }

    const updateParams = (event, id = null) => {
        const tempActivity = { ...activity, parameters: [...(activity.parameters || [])] }
        const params = tempActivity.parameters
        if (id) {
            const paramIndex = params.findIndex((e) => e.tempId === id);
            let param = { ...params[paramIndex] }
            params[paramIndex] = { type: 'circuit', tempId: param.tempId, alert_param_type_id: param.alert_param_type_id, value: { ...param.value } }
            params[paramIndex].value[event.target.name] = event.target.value
        } else {
            let param = params.find(e => e.alert_param_type_id === parseInt(event.target.name))
            if (!param) params.push(param = { alert_param_type_id: parseInt(event.target.name) })
            else Object.keys(param).forEach(key => key !== 'alert_param_type_id' && delete param[key]);
            param.value = event.target.value
        }
        setActivity(tempActivity)
    }

    const handleDelete = (id) => {

        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateAlertActivity({ deleted: true }, id).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === activity.alert_group_id) {
                            let activities = e.activities.filter(e => e.id !== activity.id)
                            props.setGroup({ ...e, activities: JSON.parse(JSON.stringify(activities)) })
                            return { ...e, activities: JSON.parse(JSON.stringify(activities)) }
                        }
                        else return e
                    })
                    props.setGroups(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                })
            } else {
                setDeleting(false)
            }
        })
    }

    const handleSave = async () => {
        setSaving(true)
        let tempAct = await props.handleSave(activity)
        if (tempAct) {
            setEdit(false)
        }
        setSaving(false)
    }

    const addCircuitParam = () => {
        let tempAct = JSON.parse(JSON.stringify(activity))
        let id = tempAct.group_parameters.find(e => e.type === 'circuit').id
        tempAct.parameters.push({ channel_id: null, threshold: null, type: 'circuit', alert_param_type_id: id, tempId: uuidv4() })
        setActivity(tempAct)
    }

    const removeCircuitParam = (event) => {
        let tempAct = JSON.parse(JSON.stringify(activity))
        let id = tempAct.group_parameters.find(e => e.type === 'circuit').id
        tempAct.parameters = tempAct.parameters.filter(e => !(e.type === 'circuit' && e.tempId === event))
        if (tempAct.parameters.length === 0) tempAct.parameters.push({ channel_id: null, threshold: null, type: 'circuit', alert_param_type_id: id, tempId: uuidv4() })
        setActivity(tempAct)
    }

    return (
        <div>
            {activity &&
                <div style={{ color: activity.status === 'Set' ? "#B8BBB9" : statusColor[activity.severity] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full bg-[#ededed] rounded-md relative'>
                    {props.bulkOption &&
                        <input type="checkbox" className="mx-2" checked={props.bulkList.includes(activity.id)} onChange={(e) =>
                            props.setBulkList(prev =>
                                e.target.checked
                                    ? [...prev, activity.id]
                                    : prev.filter(item => item !== activity.id)
                            )
                        } />
                    }
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[100px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[100px]`}>
                                {severityDict[activity.severity]}
                            </p>
                        </div>
                    }
                    <div className={`w-[90px] text-[12px]`}>{activity.status ? activity.status : " - "}</div>
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[120px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px]`}>
                                {activity.type_name}
                            </p>
                        </div>
                    }
                    {
                        props.byCircuit && context && context.getBuildingInfo &&
                        <Link className="text-left" to={`/alerts?building=${context.getBuildingInfo.building_id}&group=${activity.alert_group_id}`} target="_blank">
                            <div className="flex flex-nowrap justify-start items-center w-[120px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px] underline`}>
                                    {activity.name}
                                </p>
                            </div>
                        </Link>
                    }
                    {props.adding && equipmentList && activity.channel_type !== null &&
                        (activity.type_name === "Multi Channel Correlation" ?
                        <div className="w-[220px] text-[12px]">N/A</div>
                        :
                        <AlertsMultipleSelect
                            idAttr={activity.channel_type === 3 ? 'device_id' : 'channel_id'}
                            valAttr={activity.channel_type === 3 ? 'device_name' : 'channel_name'}
                            data={Object.values(equipmentList)}
                            dataType='circuits'
                            selectItem={updateActivity}
                            selectedList={props.selectedPoints}
                            placeholder="Select"
                            deselectAll={true} />)
                    }
                    {
                        // (!props.adding || !bulkAllowed) && equipmentList && activity.channel_type !== null && edit &&
                        // <select
                        //     onChange={updateActivity}
                        //     className={`w-[220px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        //     disabled={!edit}
                        //     name={activity.channel_type === 3 ? 'device_id' : 'channel_id'}
                        //     value={activity.channel_type === 3 ? activity.device_id : activity.channel_id}>
                        //     {!bulkAllowed && <option value=""></option>}
                        //     {
                        //         Object.values(equipmentList).map((e, id) => (
                        //             <option key={id + e} value={activity.channel_type === 3 ? e.device_id : e.channel_id}>{e.point_name}</option>
                        //         ))
                        //     }
                        // </select>
                    }
                    {!props.adding && equipmentList && activity.channel_type !== null &&
                        ((activity.channel_type !== 3 && equipmentList[activity.channel_id]) ?
                            <Link className="text-left" to={`/circuit?device=${equipmentList[activity.channel_id].device_id}&circuit=${encodeURIComponent(equipmentList[activity.channel_id].channel_name)}&tab=3`} target="_blank">
                                <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                    <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap underline w-[135px]`}>
                                        {activity.point_name}
                                    </p>
                                    {GlobalSVG.edit2()}
                                </div>
                            </Link>
                            :
                            <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[135px]`}>
                                    {activity.point_name}
                                </p>
                            </div>)
                    }
                    <select
                        onChange={updateActivity}
                        className={`w-[120px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit && !props.bulkUpdate}
                        name="schedule_type"
                        value={activity.schedule_type && activity.schedule_type}>
                        {props.bulkUpdate && <option value=""> - </option>}
                        <option value="1">Operating Hours</option>
                        <option value="2">Off-Hours</option>
                        <option value="0">Custom</option>
                    </select>
                    <select
                        onChange={updateActivity}
                        className={`w-[90px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit && !props.bulkUpdate}
                        name="frequency"
                        value={activity.frequency && activity.frequency}>
                        {props.bulkUpdate && <option value=""> - </option>}
                        <option value="0">Daily</option>
                        <option value="1"
                            disabled={context.getUserInfo && !context.getUserInfo.manage_alert_settings_real_time}
                            title={context.getUserInfo && !context.getUserInfo.manage_alert_settings_real_time ? "Please contact your advisor to select this option." : ""}
                        >Real-Time
                        </option>
                    </select>
                    <select
                        onChange={updateActivity}
                        className={`w-[90px] text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit && !props.bulkUpdate}
                        name="active"
                        value={activity.active && activity.active}>
                        {props.bulkUpdate && <option value=""> - </option>}
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <div className={`w-[120px] text-[12px] ${activity.lastTriggered ? "" : "pl-[25px]"}`}>{activity.lastTriggered ? moment(activity.lastTriggered).format('MMM D, YYYY h:mma') : " - "}</div>
                    <div className={`w-[100px] text-[12px] ${activity.lastTriggeredSince || activity.lastTriggeredSince === 0 ? "" : "pl-[25px]"}`}>{activity.lastTriggeredSince || activity.lastTriggeredSince === 0 ? parseInt(activity.lastTriggeredSince / 24) + " days, " + parseInt(activity.lastTriggeredSince) % 24 + " hours" : " - "}</div>
                    <div className="">
                        <div className="flex flex-nowrap justify-end items-center">
                            {(edit || props.bulkUpdate) &&
                                (saving ?
                                    <LoaderWheel /> :
                                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                            }
                            {
                                !edit && !props.bulkUpdate &&
                                <div className='cursor-pointer w-[21px] mr-[4px]' >
                                    <div onClick={() => setViewDetails(!viewDetails)}>{GlobalSVG.info('black')}</div>
                                </div>
                            }
                            {
                                !edit && !props.bulkUpdate && !props.bulkOption &&
                                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                    {context.getUserInfo && context.getUserInfo.manage_alert_settings && GlobalSVG.edit('black')}
                                </div>
                            }
                            {
                                !edit && !props.bulkUpdate &&
                                <div className='cursor-pointer w-[25px]'>
                                    {context.getUserInfo && context.getUserInfo.manage_alert_settings &&
                                        (deleting ?
                                            <LoaderWheel /> :
                                            <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(activity.id)}>{GlobalSVG.delete()}</div>)
                                    }
                                </div>
                            }
                        </div>

                    </div>

                </div>
            }
            {
                viewDetails && !edit && !props.bulkUpdate &&
                <div className="w-full flex flex-nowrap justify-end cursor-pointer px-6">
                    <div onClick={() => setViewDetails(false)}>
                        {GlobalSVG.close('black')}
                    </div>
                </div>
            }
            {
                activity && activity.schedule_type === 0 && (edit || viewDetails || props.bulkUpdate) &&
                <div className="flex flex-nowrap items-center justify-between p-[10px] mb-[10px]">
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Months</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                months1.map(e => (
                                    <div key={'months1' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'months', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.months && activity.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                months2.map(e => (
                                    <div key={'months2' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'months', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.months && activity.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Days</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                days1.map(e => (
                                    <div key={'days1' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'days', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.days && activity.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                days2.map(e => (
                                    <div key={'days2' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'days', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.days && activity.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Hours</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                hours1.map(e => (
                                    <div key={'hours1' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'hours', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.hours && activity.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                hours2.map(e => (
                                    <div key={'hours2' + Object.keys(e)[0]} onClick={() => edit || props.bulkUpdate ? updateActivity({ target: { name: 'hours', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${activity.hours && activity.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
            {
                activity && (edit || viewDetails || props.bulkUpdate) &&
                <div className="flex flex-nowrap items-start justify-between p-[10px] mb-[10px]">
                    {activity.group_parameters && activity.group_parameters.filter(e => e.type !== 'circuit').map((groupParam, idx) => {
                        switch (groupParam.type) {
                            case 'text': case 'number': default:
                                return <div key={'param' + idx} className="mr-[10px] w-[100px] ">
                                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{groupParam.display_name}</p>
                                    <input
                                        key={idx + 'groupparam'}
                                        type={groupParam.type}
                                        onChange={updateParams}
                                        name={groupParam.id}
                                        disabled={!edit && !props.bulkUpdate}
                                        className="text-[12px] font-semibold outline-none p-2 w-full "
                                        value={(activity.parameters && (activity.parameters.find(activityParam => activityParam.alert_param_type_id === groupParam.id)?.value))} />
                                </div>
                            case 'list':
                                return groupParam.name === 'All/Any' ?
                                    <div className="flex flex-nowrap items-center justify-between w-[300px]">
                                        <p className='font-semibold text-[12px] text-[#B8BBB9] w-[80px]'>is crossed and</p>
                                        <div key={'param' + idx} className="mr-[10px] w-1/4" >
                                            <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{groupParam.display_name}</p>
                                            <select
                                                onChange={updateParams}
                                                name={groupParam.id}
                                                className="text-[12px] font-semibold outline-none p-2 w-full "
                                                disabled={!edit}
                                                value={(activity.parameters && (activity.parameters.find(activityParam => activityParam.alert_param_type_id === groupParam.id)?.value))}>
                                                <option value=""></option>
                                                {
                                                    listParamDict[groupParam.name].map((e, id) => (
                                                        <option key={id + e} value={e}>{e}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <p className='font-semibold text-[12px] text-[#B8BBB9] w-[100px]'>of the circuits below are</p>
                                    </div>
                                    :
                                    <div key={'param' + idx} className="mr-[20px] w-1/12 " >
                                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{groupParam.display_name}</p>
                                        <select
                                            onChange={updateParams}
                                            name={groupParam.id}
                                            className="text-[12px] font-semibold outline-none p-2 w-full "
                                            disabled={!edit}
                                            value={(activity.parameters && (activity.parameters.find(activityParam => activityParam.alert_param_type_id === groupParam.id)?.value))}>
                                            <option value=""></option>
                                            {
                                                listParamDict[groupParam.name].map((e, id) => (
                                                    <option key={id + e} value={e}>{e}</option>
                                                ))
                                            }
                                        </select>
                                        {activity.type_name === "Multi Channel Correlation" && <p className='font-semibold text-[12px] text-[#B8BBB9] w-2/12'>Concurrently</p>}
                                    </div>


                        }
                    })}
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Created By</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {activity && activity.created_name}
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Created On</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {activity.created_at && moment.utc(activity.created_at).local().format('MMM D, YYYY h:mma')}
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Updated By</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {activity && activity.updated_name}
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Updated On</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {activity.updated_at && moment.utc(activity.updated_at).local().format('MMM D, YYYY h:mma')}
                        </div>
                    </div>
                </div >
            }
            {
                activity && (edit || viewDetails) && props.equipmentList &&
                activity.parameters && activity.parameters.filter(e => e.type === 'circuit').map((param, idx) => (
                    <CircuitParamLine
                        key={'paramcircuit' + idx}
                        updateParams={updateParams}
                        param={param}
                        idx={idx}
                        edit={edit}
                        activity={activity}
                        addCircuitParam={addCircuitParam}
                        removeCircuitParam={removeCircuitParam}
                        equipmentList={props.equipmentList}
                    />
                ))
            }
            {
                (edit || viewDetails || props.bulkUpdate) &&
                <div className="p-[10px]">
                    <textarea
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        type="text"
                        name="description"
                        disabled={!edit && !props.bulkUpdate}
                        placeholder="Notes"
                        className="text-sm text-[#332D41] mb-[10px] w-full outline-none p-[10px] bg-[#F3F3F3]"
                        value={activity && activity.description}
                        onChange={updateActivity}></textarea>
                </div>
            }
        </div >
    )
}
export default AlertActivityLine