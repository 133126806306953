import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highcharts";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import API from "../../utils/API";
import Loader from "../Loader";
import GlobalSVG from "../../utils/GlobalSVG";
import moment from 'moment'

export default function BarChart(props) {

    const context = useContext(AppContext);
    const [eOptions, setEOptions] = useState(null)
    const [wOptions, setWOptions] = useState(null)
    const [gOptions, setGOptions] = useState(null)
    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const [type, setType] = useState('e')
    const chartRef = useRef(null);
    const [unitDict, setUnitDict] = useState({ e: 'kWh', g: '', w: '' })
    const [hightContrast, setHighContrast] = useState(false)

    const today = moment().format("YYYY-MM-DD")
    const twoYearsAgo = moment().subtract(2, 'year').format("YYYY-MM-DD")
    const dateList = generateMonthYearRange(twoYearsAgo, today)

    useEffect(() => {
        if (!props.mainCircuitE || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();

        const get_bar_data = async () => {
            try {
                let data = await API.getElectricity(cancelToken, twoYearsAgo, today, props.mainCircuitE.device_id, 'month', 'kw', props.mainCircuitE.circuit_name)
                const values = data.data.series

                const lastYearBars = {}
                const lastYearTimestamps = dateList.slice(1, 13);
                for (let timestamp of lastYearTimestamps) {
                    lastYearBars[timestamp] = lastYearBars[timestamp] || 0
                    lastYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                }

                const thisYearBars = {}
                const thisYearTimestamps = dateList.slice(13, 25);
                for (let timestamp of thisYearTimestamps) {
                    thisYearBars[timestamp] = thisYearBars[timestamp] || 0
                    thisYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                }

                let finalThisYear = []
                let finalLastYear = []

                const months = Object.keys(thisYearBars);
                for (const [index, month] of months.entries()) {
                    let lastYearMonth = lastYearTimestamps[index]
                    finalThisYear.push({
                        name: month,
                        y: thisYearBars[month],
                        label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                    })
                    finalLastYear.push({
                        name: lastYearMonth,
                        y: lastYearBars[lastYearMonth],
                        label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                    })
                }

                setEOptions([finalThisYear, finalLastYear])
                context.setFixed30DayData(e => ({ ...e, e_bar: [finalThisYear, finalLastYear] }))
            } catch (e) {
                if (e.message === 'cancelling') return
            }
        }
        if (context.getBuildingInfo.e_channels.length !== 0) {
            if (context.getFixed30DayData === null || context.getFixed30DayData.e_bar === null) {
                get_bar_data()
            } else {
                setEOptions(context.getFixed30DayData.e_bar)
            }
        } else {
            setEOptions([])
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitE, context.getBuildingInfo])

    function generateMonthYearRange(startDate, endDate) {
        let start = moment(startDate, "YYYY-MM-DD");
        let end = moment(endDate, "YYYY-MM-DD");
        let months = [];

        while (start.isSameOrBefore(end, 'month')) {
            months.push(start.format("MMM YYYY"));
            start.add(1, 'month');
        }
        return months;
    }


    useEffect(() => {
        if (!props.mainCircuitW || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.w_channels.length !== 0) {

            const get_bar_data = async () => {
                try {
                    setUnitDict(prev => ({ ...prev, w: props.mainCircuitW.unit === 'l.' ? "L" : 'ft³' }))

                    let data = await API.getDigital(cancelToken, twoYearsAgo, today, props.mainCircuitW.device_id, 'month', props.mainCircuitW.channel_name, 'Water')
                    const values = data.data.series

                    const lastYearBars = {}
                    const lastYearTimestamps = dateList.slice(1, 13);
                    for (let timestamp of lastYearTimestamps) {
                        lastYearBars[timestamp] = lastYearBars[timestamp] || 0
                        lastYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                    }

                    const thisYearBars = {}
                    const thisYearTimestamps = dateList.slice(13, 25);
                    for (let timestamp of thisYearTimestamps) {
                        thisYearBars[timestamp] = thisYearBars[timestamp] || 0
                        thisYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                    }

                    let finalThisYear = []
                    let finalLastYear = []

                    const months = Object.keys(thisYearBars);
                    for (const [index, month] of months.entries()) {
                        let lastYearMonth = lastYearTimestamps[index]
                        finalThisYear.push({
                            name: month,
                            y: thisYearBars[month],
                            label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                        })
                        finalLastYear.push({
                            name: lastYearMonth,
                            y: lastYearBars[lastYearMonth],
                            label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                        })
                    }

                    setWOptions([finalThisYear, finalLastYear])
                    context.setFixed30DayData(e => ({ ...e, w_bar: [finalThisYear, finalLastYear] }))

                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            }
            if (context.getFixed30DayData === null || context.getFixed30DayData.w_bar === null) {
                get_bar_data()
            } else {
                setWOptions(context.getFixed30DayData.w_bar)
            }
        } else {
            setWOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitW, context.getBuildingInfo])

    useEffect(() => {
        if (!props.mainCircuitG || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.g_channels.length !== 0) {

            const get_bar_data = async () => {
                try {

                    setUnitDict(prev => ({ ...prev, g: props.mainCircuitG.unit === 'l.' ? "L" : 'ft³' }))

                    let data = await API.getDigital(cancelToken, twoYearsAgo, today, props.mainCircuitG.device_id, 'month', props.mainCircuitG.channel_name, 'Natural Gas')
                    const values = data.data.series

                    const lastYearBars = {}
                    const lastYearTimestamps = dateList.slice(1, 13);
                    for (let timestamp of lastYearTimestamps) {
                        lastYearBars[timestamp] = lastYearBars[timestamp] || 0
                        lastYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                    }

                    const thisYearBars = {}
                    const thisYearTimestamps = dateList.slice(13, 25);
                    for (let timestamp of thisYearTimestamps) {
                        thisYearBars[timestamp] = thisYearBars[timestamp] || 0
                        thisYearBars[timestamp] += values[moment(timestamp).format("YYYY-MM-01 00:00:00")] || 0
                    }

                    let finalThisYear = []
                    let finalLastYear = []

                    const months = Object.keys(thisYearBars);
                    for (const [index, month] of months.entries()) {
                        let lastYearMonth = lastYearTimestamps[index]
                        finalThisYear.push({
                            name: month,
                            y: thisYearBars[month],
                            label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                        })
                        finalLastYear.push({
                            name: lastYearMonth,
                            y: lastYearBars[lastYearMonth],
                            label: `<b>${month}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYearMonth}: ${parseInt(lastYearBars[lastYearMonth]).toLocaleString()}</b>`
                        })
                    }

                    setGOptions([finalThisYear, finalLastYear])
                    context.setFixed30DayData(e => ({ ...e, g_bar: [finalThisYear, finalLastYear] }))

                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            }
            if (context.getFixed30DayData === null || context.getFixed30DayData.g_bar === null) {
                get_bar_data()
            } else {
                setGOptions(context.getFixed30DayData.g_bar)
            }
        } else {
            setGOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitG, context.getBuildingInfo])


    useEffect(() => {
        switch (type) {
            case 'e': default:
                if (eOptions === null) return
                setCurrentData(eOptions)
                break;

            case 'w':
                if (wOptions === null) return
                setCurrentData(wOptions)
                break;

            case 'g':
                if (gOptions === null) return
                setCurrentData(gOptions)
                break;
        }
    }, [type, eOptions, wOptions, gOptions, unitDict])

    const handleType = (e) => {
        setCurrentData(null)
        setType(e.currentTarget.value)
    }

    useEffect(() => {
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: unitDict[type],
                align: 'left',
                font: {
                    size: 16,
                    weight: 'medium',
                },

            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: dateList.slice(1, 13).map(e => e.split(" ")[0]),
                crosshair: true,
                accessibility: {
                    description: 'Month'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -10
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            tooltip: {
                formatter: function () {
                    return this.point.label
                },
            },

            series: [
                {
                    name: "Last Year",
                    data: currentData && currentData[1],
                    color: "#ADD4BE"
                },
                {
                    name: "Year To Date",
                    data: currentData && currentData[0],
                    color: hightContrast ? "#DD6B20" : "#619E7B"
                }
            ],
            exporting: { enabled: false }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hightContrast, currentData, type, unitDict])

    return (
        <div className=''>
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">Monthly Usage</h2>
                            <div className="flex laptop:justify-center tablet:justify-start">
                                <div className='flex justify-end items-center'>
                                    <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed">Utility:</p>
                                </div>
                                <div className='flex justify-start '>
                                    <select value={type} onChange={handleType} className="outline-none mx-2 font-sans w-[134px] text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold">
                                        <option value='e'>Electricity</option>
                                        <option value='w'>Water</option>
                                        <option value='g'>Gas</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />
                        </div>
                        <div className='w-full flex flex-nowrap justify-end items-center cursor-pointer' onClick={() => setHighContrast(!hightContrast)}>
                            {
                                GlobalSVG.colorPalette()
                            }
                            <p className='my-4 mr-4 ml-2 text-[#F75D5F]'>High Contrast {hightContrast ? "Off" : ""}</p>
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}