import React, { useState, useEffect, useRef } from 'react';

const FilterableSelectValueName = (props) => {
  const [filter, setFilter] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  const [selectedOption, setSelectedOption] = useState('');
  const inputRef = useRef(null);

  // Update filtered options whenever the filter changes
  useEffect(() => {
    setFilteredOptions(
      props.options && props.options.filter(option =>
        option.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [filter, props.options]);

  const handleInputChange = (event) => {
    setFilter(event.target.value);
    setIsDropdownVisible(true); // Show the dropdown while typing
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.target.value);
    setFilter(option.target.content);
    setIsDropdownVisible(false); // Hide dropdown after selection
    if (props.onChange) {
        props.onChange(option); // Trigger callback
    }
  };

  const handleInputFocus = () => {
    if (!props.disabled) {
      setIsDropdownVisible(true);
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Hide dropdown when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={inputRef}
      className='w-full'
      style={{
        position: 'relative',
      }}
    >
      {/* Input Field */}
      <input
        type="text"
        value={filter}
        disabled={props.disabled}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        placeholder={props.placeholder || 'Type to filter options...'}
        style={{
          width: '100%',
          padding: '6px',
          color: '#B8BBB9',
          fontWeight: "600",
          fontSize: '12px',
          borderRadius: '7px',
          border: '1px solid #107048',
        }}
      />

      {/* Dropdown */}
      {isDropdownVisible && (
        <ul
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '5px',
            maxHeight: '150px',
            overflowY: 'auto',
            zIndex: 10,
            margin: 0,
            padding: 0,
            listStyle: 'none',
          }}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick({target:{name: props.name, value: option[props.values], content: option[props.content]}})}
                style={{
                  padding: '4px 6px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  color: '#B8BBB9',
                  backgroundColor:
                  option[props.values] === selectedOption ? '#f0f0f0' : 'white',
                }}
              >
                {option[props.content]}
              </li>
            ))
          ) : (
            <li
              style={{
                padding: '8px',
                color: '#B8BBB9',
              }}
            >
              No options found
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default FilterableSelectValueName;