import { useContext, useEffect, useState, useRef } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import MaintenanceActivitiesLines from "../MaintenanceActivitiesLines";
import MaintenanceLogsLines from "../MaintenanceLogsLines";
import { Link, useNavigate } from "react-router-dom";
import MaintenanceSubscriptionsModal from "../MaintenanceSubscriptionsModal";

const MaintenanceDetailsModal = (props) => {

    const context = useContext(AppContext);
    const [group, setGroup] = useState()
    const [creating, setCreating] = useState(false)
    const [showSubscriptions, setShowSubscriptions] = useState(false)
    const [edit, setEdit] = useState(props.create ? true : false)
    const groupStatusColor = { 'Active': '#107048', 'Inactive': '#F75D5F' }
    const modal = useRef()
    const navigate = useNavigate();

    useEffect(() => {
        if (props.create) setGroup({ building_id: context.getBuildingInfo.building_id })
        else {
            let tempGroup = JSON.parse(JSON.stringify(props.groups.find((e) => e.id === props.group)))
            for (let key in tempGroup) {
                if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                    tempGroup[key] = Math.round(tempGroup[key])
                }
            }
            setGroup(tempGroup)
        }
    }, [props.groups, props.group, props.create, context.getBuildingInfo])

    const handleSave = () => {
        
        setCreating(true)
        if (props.create) {
            API.createMaintenanceGroup(group).then(async res => {
                res.data.activities = []
                res.data.logs = []
                props.setGroups(e => [...e, res.data]);
                setGroup(res.data)
                navigate(`/maintenance?building=${context.getBuildingInfo.building_id}&group=${res.data.id}`)
                props.setShowCreate(false)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        } else {
            let allowed = ["name", "type", "status", "description"]
            let payload = JSON.parse(JSON.stringify(group))
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateMaintenanceGroup(payload, group.id).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === group.id) {
                        return JSON.parse(JSON.stringify(group));
                    }
                    else return e
                });
                updatedGroups.sort((a, b) => a.id - b.id);
                props.setGroups(updatedGroups);
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        }
    }

    const updateGroup = (event) => {
        setGroup(e => ({ ...e, [event.target.name]: event.target.value }))
    }

    return (
        <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center alert-detail-modal'>
            <div ref={modal} className='card w-[80vw] h-[75vh] m-2 p-[40px] pt-[0] background-white rounded-md overflow-auto' onClick={(e) => { e.stopPropagation() }}>
                {group && <div className='min-w-[920px]'>
                    <div className="flex flex-nowrap justify-end items-center pt-[20px] w-full">
                        {
                            !creating && !edit &&
                            <div className="w-[15px] h-[15px] cursor-pointer" onClick={() => setEdit(true)}>
                                {GlobalSVG.edit('black')}
                            </div>
                        }
                        {
                            !creating && edit &&
                            <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[12px] rounded-[4px] cursor-pointer" onClick={handleSave}>
                                Save
                            </div>
                        }
                        {
                            creating &&
                            <LoaderWheel />
                        }
                        {
                            props.create ?
                                <div className='cursor-pointer ml-3' onClick={() => props.setShowCreate(false)}>{GlobalSVG.closeXBig('black')}</div>
                                :
                                <Link className='cursor-pointer ml-3' to={`/maintenance?building=${context.getBuildingInfo.building_id}`}>{GlobalSVG.closeXBig('black')}</Link>
                        }

                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-5/12 mr-4">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Group Name</p>
                            <input
                                type="text"
                                onChange={updateGroup}
                                name="name"
                                disabled={!edit}
                                className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41] mb-[20px] w-full outline-none hide-input-background"
                                defaultValue={group.name} />
                        </div>
                        <div className="w-5/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Building</p>
                            <div className="flex flex-row flex-nowrap laptop:gap-14 tablet:gap-10 justify-between mobile:pr-4 tablet:pr-6 min-h-[44px] items-center">
                                <div className="flex flex-row items-center gap-2">
                                    <div className="justify-end">
                                        <img className='navbar-image-limiter rounded-[4px]' src={context.getBuildingInfo.image ? context.getBuildingInfo.image : require('../../assets/images/placeholder-image.png')} alt="" />
                                    </div>
                                    <div className="justify-start">
                                        <p className="font-bold laptop:text-sm tablet:text-xs leading-5 truncate laptop:max-w-none tablet:max-w-[180px] text-start">
                                            {context.getBuildingInfo.building}
                                        </p>
                                        <div className="flex gap-0 laptop:w-content">
                                            <p className="font-normal text-[11px] leading-5 truncate laptop:max-w-none tablet:max-w-[180px]" >
                                                {`${context.getBuildingInfo.address} ${context.getBuildingInfo.city} ${context.getBuildingInfo.province}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[25px]">
                            <select
                                onChange={updateGroup}
                                className={`w-[160px] rounded-md p-1 font-semibold border border-[${groupStatusColor[group.status]}] text-[${groupStatusColor[group.status]}]`}
                                disabled={!edit}
                                name="status"
                                value={group.status}>
                                <option value=""> - </option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-8/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Description & Notes</p>
                            <textarea
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                type="text"
                                name="description"
                                disabled={!edit}
                                className="text-sm text-[#332D41] mb-[10px] w-full outline-none hide-input-background"
                                defaultValue={group.description}
                                onChange={updateGroup}></textarea>
                        </div>
                        <div className="">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Maint. Type</p>
                            <select
                                onChange={updateGroup}
                                className={`w-[120px] text-[12px] font-semibold mb-[20px]`}
                                disabled={!edit}
                                name="type"
                                value={group.type}>
                                <option value=""> - </option>
                                <option value="Filter Change">Filter Change</option>
                                <option value="Cleaning">Cleaning</option>
                                <option value="Repair">Repair</option>
                                <option value="Upgrade">Upgrade</option>
                                <option value="Replacement">Replacement</option>
                                <option value="Inspection">Inspection</option>
                            </select>
                        </div>
                        <div className="w-[160px] ml-2">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Created By</p>
                            <p className="text-[12px] font-semibold mb-[20px] ">{group.created_by && group.created_by}</p>
                        </div>
                        <div className="w-[160px]">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Created On</p>
                            <p className="text-[12px] font-semibold mb-[20px] ">{group.created_at && group.created_at.split("T")[0]}</p>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="py-[2px] px-2 bg-[#385B46] text-[white] text-[12px] rounded-[4px] cursor-pointer flex flex-nowrap items-center justify-center mt-[10px]" onClick={() => setShowSubscriptions(true)}>
                            <p className="mr-2 font-semibold">SUBSCRIBE</p> {GlobalSVG.ringer()}
                        </div>
                    </div>
                    {!props.create &&
                        <MaintenanceActivitiesLines group={group} setGroup={setGroup} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />}
                    {!props.create &&
                        <MaintenanceLogsLines group={group} setGroup={setGroup} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />}
                </div>}
            </div>
            {group && group.subscribers && showSubscriptions && <MaintenanceSubscriptionsModal subscribers={group.subscribers} groups={props.groups} setGroup={setGroup} setGroups={props.setGroups} setShowSubscriptions={setShowSubscriptions} groupId={props.group} />}
        </div>
    )
}

export default MaintenanceDetailsModal;