import { useContext, useEffect, useState, useRef } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import { Link, useNavigate } from "react-router-dom";
import AlertSubscriptionsModal from "../AlertSubscriptionsModal";
import Tooltip from "../../components/ToolTip";
import AlertActivitiesSection from "../AlertActivitiesSection";
import AlertLogsSection from "../AlertLogsSection";
import moment from 'moment'
import FilterableSelect from '../../components/FilterableSelectValueName';

const AlertDetailsModal = (props) => {

    const context = useContext(AppContext);
    const [group, setGroup] = useState()
    const [creating, setCreating] = useState(false)
    const [showSubscriptions, setShowSubscriptions] = useState(false)
    const [edit, setEdit] = useState(props.create ? true : false)
    const [currentStatus, setCurrentStatus] = useState(null)
    const groupStatusColor = { "1": '#107048', "0": '#F75D5F' }
    const severityColour = { "0": "#B8BBB9", "1": '#F9941E', "2": '#F75D5F' }
    const modal = useRef()
    const navigate = useNavigate();
    const statusColorDict = { 0: "#B53838", 1: "#DCB052", 2: "#DCB052", 3: "#46775A" }
    const statusDict = { 0: "Closed", 1: "Half", 2: "Partial", 3: "Open" }

    useEffect(() => {
        if (props.create) setGroup({ building_id: context.getBuildingInfo.building_id, active: "1", deleted: 0, severity: "0" })
        else {
            let tempGroup = props.groups.find((e) => e.id === props.group) || {};
            tempGroup = JSON.parse(JSON.stringify(tempGroup))
            if (tempGroup.actuator_id) getCurrentStatus()
            for (let key in tempGroup) {
                if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                    tempGroup[key] = Math.round(tempGroup[key])
                }
            }
            setGroup(tempGroup)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groups, props.group, props.create, context.getBuildingInfo])

    const handleSave = () => {
        ;
        setCreating(true)
        if (props.create) {
            let payload = JSON.parse(JSON.stringify(group))
            API.createAlertGroup(payload).then(async res => {
                res.data.activities = []
                res.data.logs = []
                res.data.type_name = props.alertTypes[res.data.alert_type_id].name
                res.data.channel_type = props.alertTypes[res.data.alert_type_id].channel_type
                props.setGroups(e => [...e, res.data])
                setGroup(res.data)
                navigate(`/alerts?building=${context.getBuildingInfo.building_id}&group=${res.data.id}`)
                props.setShowCreate(false)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        } else {
            let allowed = ["name", "active", "description", "severity"]
            let payload = JSON.parse(JSON.stringify(group))
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateAlertGroup(payload, group.id).then(async putRes => {
                let tempGroup = await API.getAlertGroup(group.id)
                tempGroup = tempGroup.data
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === group.id) {
                        return tempGroup
                    }
                    else return e
                });
                updatedGroups.sort((a, b) => a.id - b.id);
                props.setGroups(updatedGroups);
                setGroup(tempGroup)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        }
    }

    const updateGroup = (event) => {
        setGroup(e => ({ ...e, [event.target.name]: event.target.value }))
    }

    const getCurrentStatus = async (cancelToken) => {
        try {
            if (!group || !group.actuator_id) return
            if (!cancelToken) cancelToken = API.cancelToken();
            let currenStat = await API.getActuatorStatus(cancelToken, group.actuator_id)
            setCurrentStatus({ status: currenStat.data, timestamp: moment().format('YYYY-MM-DD HH:mm:ss') })
        } catch (e) {
            if (e.message === 'cancelling') return
            if (e.response && e.response.data) {
                Swal.fire({
                    title: 'Error.',
                    text: e.response.data,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            } else {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: "An error occurred, please contact us.",
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    return (
        <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center alert-detail-modal'>
            <div ref={modal} className='card w-[80vw] h-[75vh] m-2 p-[40px] pt-[0] background-white rounded-md overflow-auto' onClick={(e) => { e.stopPropagation() }}>
                {group && <div className='min-w-[920px]'>
                    <div className="flex flex-nowrap justify-end items-center pt-[20px] w-full">
                        {
                            !creating && !edit &&
                            <div className="w-[15px] h-[15px] cursor-pointer" onClick={() => setEdit(true)}>
                                {GlobalSVG.edit('black')}
                            </div>
                        }
                        {
                            !creating && edit &&
                            <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[12px] rounded-[4px] cursor-pointer" onClick={handleSave}>
                                Save
                            </div>
                        }
                        {
                            creating &&
                            <LoaderWheel />
                        }
                        {
                            props.create ?
                                <div className='cursor-pointer ml-3' onClick={() => props.setShowCreate(false)}>{GlobalSVG.closeXBig('black')}</div>
                                :
                                <Link className='cursor-pointer ml-3' to={`/alerts?building=${context.getBuildingInfo.building_id}`}>{GlobalSVG.closeXBig('black')}</Link>
                        }

                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-5/12 mr-4">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Alert Group Name</p>
                            <input
                                type="text"
                                onChange={updateGroup}
                                name="name"
                                disabled={!edit}
                                className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41] mb-[20px] w-full outline-none hide-input-background"
                                defaultValue={group.name} />
                        </div>
                        <div className="w-5/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Building</p>
                            <div className="flex flex-row flex-nowrap laptop:gap-14 tablet:gap-10 justify-between mobile:pr-4 tablet:pr-6 min-h-[44px] items-center">
                                <div className="flex flex-row items-center gap-2">
                                    <div className="justify-end">
                                        <img className='navbar-image-limiter rounded-[4px]' src={context.getBuildingInfo.image ? context.getBuildingInfo.image : require('../../assets/images/placeholder-image.png')} alt="" />
                                    </div>
                                    <div className="justify-start">
                                        <p className="font-bold laptop:text-sm tablet:text-xs leading-5 truncate laptop:max-w-none tablet:max-w-[180px] text-start">
                                            {context.getBuildingInfo.building}
                                        </p>
                                        <div className="flex gap-0 laptop:w-content">
                                            <p className="font-normal text-[11px] leading-5 truncate laptop:max-w-none tablet:max-w-[180px]" >
                                                {`${context.getBuildingInfo.address} ${context.getBuildingInfo.city} ${context.getBuildingInfo.province}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[25px]">
                            <select
                                onChange={updateGroup}
                                className={`w-[160px] rounded-md p-1 font-semibold border border-[${groupStatusColor[group.active]}] text-[${groupStatusColor[group.active]}] outline-none hide-input-background`}
                                disabled={!edit}
                                name="active"
                                value={group.active}>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-6/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Description & Notes</p>
                            <textarea
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                type="text"
                                name="description"
                                disabled={!edit}
                                className="text-sm text-[#332D41] mb-[10px] mr-[10px] w-full outline-none hide-input-background"
                                defaultValue={group.description}
                                onChange={updateGroup}></textarea>
                        </div>
                        <div className="w-[195px]">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Type</p>
                            <div className="flex flex-nowrap justify-centre items-center">
                                {!props.create &&
                                    <select
                                        className={`w-full text-[12px] font-semibold text-[#B8BBB9] outline-none hide-input-background`}
                                        disabled={true}
                                        name="alert_type_id"
                                        value={group.alert_type_id}>
                                        <option value=""> - </option>
                                        {props.alertTypes && Object.keys(props.alertTypes).sort((a, b) => props.alertTypes[a].name.localeCompare(props.alertTypes[b].name)).map(e => (
                                            <option key={e + 'alerttypes'} value={e}>{props.alertTypes[e].name}</option>
                                        ))}
                                    </select>}
                                {props.alertTypes && props.create &&
                                    <FilterableSelect
                                        options={Object.values(props.alertTypes).sort((a, b) => a.name.localeCompare(b.name))}
                                        values="id"
                                        content="name"
                                        disabled={!props.create}
                                        placeholder=" - "
                                        name='alert_type_id'
                                        onChange={(event) => updateGroup({ target: { name: "alert_type_id", value: event.target.value } })} />}
                                <Tooltip
                                    text={group && group.type_description ? group.type_description : props.alertTypes && group.alert_type_id && props.alertTypes[group.alert_type_id].description}
                                    right="0"
                                    left="400%"
                                    top="0"
                                    width="300px"
                                    height="150px"
                                    textAlign="left"
                                    show={true}>
                                    <div className="ml-2">
                                        {GlobalSVG.info('black', '15', '15')}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="w-[120px]">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Category</p>
                            <div className={`w-full text-[12px] text-[#B8BBB9] font-semibold px-1 py-2`}>
                                {props.alertTypes && group.alert_type_id && props.alertTypes[group.alert_type_id].category}
                            </div>
                        </div>
                        <div className="w-[100px]">
                            <p className={`text-[12px] text-[#B8BBB9] font-semibold mb-[10px]`}>Severity</p>
                            <select
                                onChange={updateGroup}
                                className={`w-full text-[12px] font-semibold mb-[20px] border-[${severityColour[group.severity]}] text-[${severityColour[group.severity]}]`}
                                disabled={!edit}
                                name="severity"
                                value={group.severity}>
                                <option value="0">Info</option>
                                <option value="1">Caution</option>
                                <option value="2">Critical</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        {
                            context &&
                            context.getUserInfo &&
                            !props.create &&
                            (context.getUserInfo.manage_my_alert_subscription || context.getUserInfo.manage_others_alert_subscription) &&
                            <div className="py-[2px] px-2 bg-[#385B46] text-[white] text-[12px] rounded-[4px] cursor-pointer flex flex-nowrap items-center justify-center mt-[10px]" onClick={() => setShowSubscriptions(true)}>
                                <p className="mr-2 font-semibold">SUBSCRIBE</p> {GlobalSVG.ringer()}
                            </div>
                        }
                        {group.alert_type_id && [1, 2].includes(props.alertTypes[group.alert_type_id].channel_type) &&
                            <div className="flex flex-nowrap justify-centre items-center">
                                {
                                    props.create ?
                                        <div className="w-[140px]">
                                            <p className='text-[12px] font-semibold text-[#B8BBB9]'>Linked Actuator</p>
                                            <select
                                                onChange={updateGroup}
                                                className={`w-full text-[12px] font-semibold outline-none hide-input-background ml-[-4px]`}
                                                disabled={!props.create}
                                                name="actuator_id"
                                                value={group.actuator_id}>
                                                <option value=""> - </option>
                                                {context.getBuildingInfo.actuators && context.getBuildingInfo.actuators.map(e => (
                                                    <option key={e.id + 'alerttypes'} value={e.id}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        :
                                        group && group.actuator_id &&
                                        <div className="w-[140px]">
                                            <p className='text-[12px] font-semibold text-[#B8BBB9]'>Linked Actuator</p>
                                            <Link to={`/circuit?device=${context.getBuildingInfo.actuators.find(e => e.id === group.actuator_id)?.device_id}&circuit=${context.getBuildingInfo.actuators.find(e => e.id === group.actuator_id)?.channel_name}&tab=4`} target="_blank">
                                                <div className={`w-[140px] text-[12px] font-semibold py-2 underline`}>
                                                    {context.getBuildingInfo.actuators && context.getBuildingInfo.actuators.find(e => e.id === group.actuator_id)?.name}
                                                </div>
                                            </Link>
                                        </div>
                                }
                                {(props.create || (group && group.actuator_id))
                                    && <Tooltip
                                        text="If any alert activity in this group is triggered, and the linked actuator is auto-enabled, it will automatically be closed."
                                        right="0"
                                        left="400%"
                                        top="0"
                                        width="300px"
                                        height="90px"
                                        textAlign="left"
                                        show={true}>
                                        <div className="ml-2">
                                            {GlobalSVG.info('black', '15', '15')}
                                        </div>
                                    </Tooltip>}
                            </div>
                        }
                        {
                            group && group.alert_type_id && [1, 2].includes(props.alertTypes[group.alert_type_id].channel_type) && group.actuator_id && !props.create &&
                            <div className="flex flex-nowrap items-center justify-center">
                                <div className="flex flex-nowrap items-center justify-center mr-[40px]">
                                    <div className='cursor-pointer mb-[20px]' onClick={getCurrentStatus}>{GlobalSVG.refresh()}</div>
                                    <div className='ml-[20px]'>
                                        <p className='text-[12px] font-semibold text-[#B8BBB9]'>Last Status</p>
                                        <p className='text-[12px] font-semibold mb-[20px] py-2'>{currentStatus && currentStatus.timestamp}</p>
                                    </div>
                                </div>
                                <div className='ml-[20px]'>
                                    <p className='text-[12px] font-semibold text-[#B8BBB9]'>Position</p>
                                    <p style={{ color: currentStatus ? statusColorDict[currentStatus.status] : "black" }} className='mb-[20px] py-2'>{currentStatus && statusDict[currentStatus.status]}</p>
                                </div>
                            </div>
                        }
                        <div className="flex flex-nowrap items-center justify-between w-[260px]">
                            <div className="w-[120px] mr-2">
                                <p className="text-[12px] text-[#B8BBB9] font-semibold">Created By</p>
                                <p className="text-[12px] font-semibold mb-[20px] py-2">{group.created_name && group.created_name}</p>
                            </div>
                            <div className="w-[100px]">
                                <p className="text-[12px] text-[#B8BBB9] font-semibold">Created On</p>
                                <p className="text-[12px] font-semibold mb-[20px] py-2">{group.created_at && group.created_at.split("T")[0]}</p>
                            </div>
                        </div>
                    </div>
                    {!props.create &&
                        <AlertActivitiesSection group={group} setGroup={setGroup} groups={props.groups} setGroups={props.setGroups} equipmentList={props.equipmentList} />}
                    {!props.create &&
                        <AlertLogsSection group={group} setGroup={setGroup} groups={props.groups} setGroups={props.setGroups} equipmentList={props.equipmentList} />}
                </div>}
            </div>
            {group && group.subscribers && showSubscriptions && <AlertSubscriptionsModal subscribers={group.subscribers} groups={props.groups} setGroup={setGroup} setGroups={props.setGroups} setShowSubscriptions={setShowSubscriptions} groupId={props.group} />}
        </div >
    )
}

export default AlertDetailsModal;