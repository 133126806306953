import React, { useEffect, useState } from 'react';
import './style.css'
import { useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { Link } from 'react-router-dom';
import GlobalSVG from '../../utils/GlobalSVG';
import API from "../../utils/API";
import Swal from 'sweetalert2';
import SlideToggle from '../../components/SlideToggle';

const Profile = () => {

    const context = useContext(AppContext);
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState()
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (!context.getUserInfo) return
        setUser({ alternate_email: context.getUserInfo.alternate_email, primary_tel: context.getUserInfo.primary_tel, weekly_alert_report: context.getUserInfo.weekly_alert_report, weekly_maintenance_report: context.getUserInfo.weekly_maintenance_report })
    }, [context.getUserInfo])

    const updateUser = (e) => {
        let input = e.target.value
        if (e.target.name === 'primary_tel') {
            input = e.target.value.replace(/\D/g, '');
            input = '+' + input;
            if (input.length > 2) { // +1 is 2 characters
                input = input.slice(0, 2) + ' (' + input.slice(2);
            }
            if (input.length > 7) {
                input = input.slice(0, 7) + ') ' + input.slice(7);
            }
            if (input.length > 12) {
                input = input.slice(0, 12) + ' - ' + input.slice(12, 16);
            }

        }
        setUser(prev => ({ ...prev, [e.target.name]: input }))
    }

    const postValue = () => {
        setLoading(true)
        let tracker = false
        if (user.alternate_email) {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(user.alternate_email)) {
                setErrors(prev => ({ ...prev, alternate_email: 'Please enter a valid email address.' }))
                tracker = true
            } else {
                setErrors(({ alternate_email: _, ...rest }) => rest)
            }
        }
        if (user.primary_tel) {
            const phonePattern = /^\+\d{1,3}\s\(\d{3}\)\s\d{3}\s-\s\d{4}$/;
            if (!phonePattern.test(user.primary_tel)) {
                setErrors(prev => ({ ...prev, primary_tel: 'Please enter a valid phone number.' }))
                tracker = true
            } else {
                setErrors(({ primary_tel: _, ...rest }) => rest)
            }
        }

        if (tracker) return

        API.updateCorrespondance(user).then(res => {
            context.setForceRefresh(prev => !prev)
            setEdit(false)
        }).catch(e => {
            console.log(e)
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        }).finally(f => {
            setLoading(false)
        })
    }

    return (
        <div className='tablet:p-8 mobile:p-3 h-[80vh]'>
            {
                context.getUserInfo ?
                    <div className='card tablet:p-8 mobile:p-3 h-full'>
                        <h1 className='text-3xl mb-8 font-bold'>Profile</h1>
                        <div className='flex flex-nowrap items-center justify-start pb-8 input-border'>
                            <img
                                className='navbar-image-limiter w-12 h-12 rounded-full block'
                                src={context.getUserInfo.logo ? context.getUserInfo.logo : require('../../assets/images/placeholder-image.png')} alt="" />
                            <p className='ml-4 text-2xl font-medium'>{context.getUserInfo.client_name}</p>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-full flex flex-wrap items-center justify-start'>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>First Name</p>
                                <p className='mobile:w-3/5 tablet:w-2/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.firstname}</p>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>Last Name</p>
                                <p className='mobile:w-3/5 tablet:w-2/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.lastname}</p>
                            </div>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-1/3 flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-6/12 text-base input-label-color'>Email</p>
                                <p className='mobile:w-3/5 tablet:w-6/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.email}</p>
                            </div>
                            <div className='w-1/3 flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-6/12 text-base input-label-color'>Correspondance Email</p>
                                <input
                                    className='mobile:w-3/5 tablet:w-5/12 mobile:text-base tabet:text-xl input-color outline-none hide-input-background'
                                    type="email"
                                    disabled={!edit}
                                    name="alternate_email"
                                    value={user && user.alternate_email}
                                    onChange={updateUser} />
                                {
                                    errors.alternate_email &&
                                    <p className="text-[10px] text-[red] font-semibold mb-2">{errors.alternate_email}</p>
                                }
                            </div>
                            <div className='w-1/3 flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-6/12 text-base input-label-color'>Mobile Number</p>
                                <input
                                    className='mobile:w-3/5 tablet:w-5/12 mobile:text-base tabet:text-xl input-color outline-none hide-input-background'
                                    type="tel"
                                    disabled={!edit}
                                    name="primary_tel"
                                    value={user && user.primary_tel}
                                    onChange={updateUser} />
                                {
                                    errors.primary_tel &&
                                    <p className="text-[10px] text-[red] font-semibold mb-2">{errors.primary_tel}</p>
                                }
                            </div>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-full flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>Password</p>
                                <Link to={'/passchange-in'} className='mobile:w-3/5 tablet:w-1/2 flex flex-nowrap items-center'>
                                    <div className='w-6 h-6'>
                                        {
                                            GlobalSVG.key()
                                        }
                                    </div>
                                    <p className='text-base input-color ml-2'>Change Password</p>
                                </Link>
                            </div>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <SlideToggle disabled={!edit} setOptions={setUser} options={user} id="weekly_alert_report" name="Receive Weekly Portfolio Alert Summary" />
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <SlideToggle disabled={!edit} setOptions={setUser} options={user} id="weekly_maintenance_report" name="Receive Weekly Portfolio Maintenance Summary" />
                        </div>

                        {
                            edit && !loading &&
                            <div className='mt-6 w-full flex flex-nowrap justify-end items-center'>
                                <button className='submit-button-small' onClick={postValue}>
                                    Save
                                </button>
                            </div>
                        }
                        {
                            !edit && !loading &&
                            <div className='mt-6 w-full flex flex-nowrap justify-end items-center'>
                                <button className='submit-button-small' onClick={() => setEdit(true)}>
                                    Edit
                                </button>
                            </div>
                        }
                        {
                            edit && loading &&
                            <div className='mt-6 w-full flex flex-nowrap justify-end items-center'>
                                <Loader />
                            </div>
                        }
                    </div>
                    :
                    <Loader />
            }
        </div>
    );
};

export default Profile;