import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import GlobalSVG from "../../utils/GlobalSVG";
import "./style.css";
import { AppContext } from "../../utils/ContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import MainGraph from "../../components/MainGraph";
import DiagramFuncs from "../../utils/DiagramFuncs";
import MultipleSelect from "../../components/MultipleSelect";
import API from "../../utils/API";
import GraphFuncs from "../../utils/GraphFuncs";

import {
  HierarchicalTree,
  DataBinding,
  DiagramComponent,
  NodeConstraints,
  SnapConstraints,
  Inject,
  PrintAndExport,
  DiagramTools,
} from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";
import {
  ItemDirective,
  ItemsDirective,
  ToolbarComponent,
} from "@syncfusion/ej2-react-navigations";
import html2canvas from "html2canvas";
import ContextMenu from "../../components/ContextMenu";

const Distribuition = () => {
  const [distHierarchy, setDistHierarchy] = useState(null);
  const [egHierarchy, setEgHierarchy] = useState(null);
  const [catHierarchy, setCatHierarchy] = useState(null);
  const [wHierarchy, setWHierarchy] = useState(null);
  const [gHierarchy, setGHierarchy] = useState(null);
  const [collapseAll, setCollapseAll] = useState(false)
  const location = useLocation();
  const context = useContext(AppContext);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [circuits, setCircuits] = useState(null);
  const [dimensions, setDimensions] = useState({ width: "100%", height: "500px" })
  const [initList, setInitList] = useState(null);

  const [buildingOptions, setBuildingOptions] = useState([]);
  const [circuitOptions, setCircuitOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [eGroupOptions, setEGroupOptions] = useState([]);

  const [selectedBuildings, setSelectedBuildings] = useState(null);

  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [nodePan, setNodePan] = useState(null);
  const [maptype, setMapType] = useState("1")

  const typeDict = { '1': 'circuit', '2': 'circuit', '3': 'circuit', '4': 'gas', '5': 'water' }
  const mapTypeDict = { '1': 'e_channels', '2': 'e_channels', '3': 'e_channels', '4': 'g_channels', '5': 'w_channels' }

  const [openFilters, setOpenFilters] = useState(false)

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [clickedNode, setClickedNode] = useState({});
  const [exporting, setExporting] = useState(false);
  const [positionContextMenu, setPositionContextMenu] = useState({
    x: 0,
    y: 0,
  });

  const [distHierarchy2, setDistHierarchy2] = useState(null);
  const [catHierarchy2, setCatHierarchy2] = useState(null);
  const [egHierarchy2, setEgHierarchy2] = useState(null);
  const [wHierarchy2, setWHierarchy2] = useState(null);
  const [gHierarchy2, setGHierarchy2] = useState(null);
  const hierarchyDict = { distHierarchy: distHierarchy2, catHierarchy: catHierarchy2, egHierarchy: egHierarchy2, wHierarchy: wHierarchy2, gHierarchy: gHierarchy2 }
  const hierarchyDictO = { distHierarchy: distHierarchy, catHierarchy: catHierarchy, egHierarchy: egHierarchy, wHierarchy: wHierarchy, gHierarchy: gHierarchy }

  const [loaded, setLoaded] = useState(false)
  const [currentHierarchy, setCurrentHierarchy] = useState(null)
  let [diagramInstance, setDiagramInstance] = useState(null)
  const [paramsSet, setParamsSet] = useState(false)
  const [startEnd, setStartEnd] = useState(false)

  useEffect(() => {
    if (expanded || !context.nodeSelected || Object.keys(context.nodeSelected).length === 0) setDimensions(prev => ({ ...prev, height: "650px" }))
    else setDimensions(prev => ({ ...prev, height: "500px" }))
  }, [expanded, context.nodeSelected])

  useEffect(() => {
    if (queryParams.size === 0 || queryParams.get('building') === "null") {
      navigate('/map')
    }
    if (queryParams.get('refresh')) {
      navigate(`/distribution?building=${queryParams.get('building')}`)
      window.location.reload(true);
    }
    if (context.getBuilding) return
    context.setBuilding(queryParams.get('building'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (!context.getBuildingInfo || selectedBuildings === null) return;
    var arrChanel = [];
    var arrEGroups = [];
    var arrCats = [];

    // Set circuit options
    if (selectedBuildings.length !== 0) {
      for (let c of context.getBuildingInfo[mapTypeDict[maptype]]) {
        if (selectedBuildings.includes(c.building_distribution)) {
          arrChanel.push(c.channel_name.trim());
          arrEGroups.push(c.equipment_group && c.equipment_group.trim());
          arrCats.push(c.category && c.category.trim());
        }
      }
      arrChanel = Array.from(new Set(arrChanel));
      arrEGroups = Array.from(new Set(arrEGroups));
      arrEGroups = arrEGroups.filter(e => e !== null)
      arrEGroups = arrEGroups.map(e => ({ eg_id: "Group: " + e, eg: e }))
      arrCats = new Set(arrCats)
      arrCats.delete("Source")
      arrCats.delete("Building Distribution")
      arrCats = Array.from(arrCats);
      arrCats = arrCats.filter(e => e !== null)
      arrChanel.sort(function (a, b) {
        var titleA = a.toUpperCase();
        var titleB = b.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setCircuitOptions(arrChanel);
      arrEGroups.sort(function (a, b) {
        var titleA = a.eg.toUpperCase();
        var titleB = b.eg.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setEGroupOptions(arrEGroups);
      arrCats.sort(function (a, b) {
        var titleA = a.toUpperCase();
        var titleB = b.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setCategoryOptions(arrCats);
    }

    const cancelToken = API.cancelToken();

    var currentDate = new Date();
    var hour = currentDate.toLocaleTimeString('en-US', { hour12: false }).split(":")[0]
    var currentDateFormatted = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
    const getData = async ()=>{
      let alerts
      try {
        alerts = await API.getAlertsByCirctuit(cancelToken, context.getBuildingInfo.building_id)
      }catch(e){
        if (e.message === 'cancelling') return
        console.log(e)
        alerts = null
      }

      let alertsDict = {};
      if (alerts){
        alerts.data.forEach(e =>
        (alertsDict[e.channel_type] = alertsDict[e.channel_type] || {}) &&
        (alertsDict[e.channel_type][e.channel_id] = alertsDict[e.channel_type][e.channel_id] || {}) &&
        (alertsDict[e.channel_type][e.channel_id][e.severity] = e.count)
        );
      }

      API.getHierarchyConsumption(cancelToken, currentDateFormatted, hour, context.getBuildingInfo.building_id).then(async res => {
        let main = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)
        let dist = DiagramFuncs.getTree(context.getBuildingInfo, "1", selectedBuildings, alertsDict['0'] || {});
        const sum = res.data[main.circuit_name]
        for (let node of dist) {
          if (node.name.includes('Cap Bank')) {
            node.consumption = 'N/A'
            node.percentage = 'N/A'
            node.persort = 0
            continue
          }
          node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
          node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
          node.persort = node.name in res.data ? ((res.data[node.name] / sum) * 100) : 0
        }
        dist.sort((a, b) => parseFloat(a.persort) - parseFloat(b.persort))
        sortHierarchy(dist)
        setDistHierarchy(dist);
        setDistHierarchy2(dist);
  
        let eg = DiagramFuncs.getTree(context.getBuildingInfo, "2", selectedBuildings, alertsDict['0'] || {});
        let egDict = {}
        let egArr = []
        for (let node of eg) {
          if (node.dataType === 'equipment_group') {
            egArr.push(node)
            continue
          }
          egDict[node.parent] = egDict[node.parent] || 0
          egDict[node.parent] += res.data[node.name]
          node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
          node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
        }
        for (let node of egArr) {
          node.consumption = node.id in egDict ? egDict[node.id].toFixed(3) + " kWh" : "0 kWh"
          node.percentage = node.id in egDict ? ((egDict[node.id] / sum) * 100).toFixed(2) : "0"
        }
        sortHierarchy(eg)
        setEgHierarchy(eg);
        setEgHierarchy2(eg);

        let cat = DiagramFuncs.getTree(context.getBuildingInfo, "3", selectedBuildings, alertsDict['0'] || {});
        let catDict = {}
        let subcatDict = {}
        let catArr = []
        let subcatArr = []
        for (let node of cat) {
          if (node.dataType === 'category') {
            catArr.push(node)
            continue
          }
          if (node.dataType === 'sub_category') {
            subcatArr.push(node)
            continue
          }
          subcatDict[node.parent] = subcatDict[node.parent] || 0
          subcatDict[node.parent] += res.data[node.name]
          node.consumption = node.name in res.data ? res.data[node.name].toFixed(2) + " kWh" : "0 kWh"
          node.percentage = node.name in res.data ? ((res.data[node.name] / sum) * 100).toFixed(2) : "0"
        }
        for (let node of subcatArr) {
          catDict[node.parent] = catDict[node.parent] || 0
          catDict[node.parent] += subcatDict[node.id]
          node.consumption = node.id in subcatDict ? subcatDict[node.id].toFixed(3) + " kWh" : "0 kWh"
          node.percentage = node.id in subcatDict ? ((subcatDict[node.id] / sum) * 100).toFixed(2) : "0"
        }
        for (let node of catArr) {
          node.consumption = node.id in catDict ? catDict[node.id].toFixed(3) + " kWh" : "0 kWh"
          node.percentage = node.id in catDict ? ((catDict[node.id] / sum) * 100).toFixed(2) : "0"
        }
        sortHierarchy(cat)
        setCatHierarchy(cat);
        setCatHierarchy2(cat);

        const dUnits = { 'l.': "L", "ft^3": "ft³" }

        if (context.getBuildingInfo.g_channels.length !== 0) {

          API.getHierarchyConsumptionD(cancelToken, context.getBuildingInfo.building_id, currentDateFormatted, hour, 'Natural Gas').then(async gRes => {
            let main = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.g_hierarchy)
            const sum = gRes.data[main.channel_name]
            let g = DiagramFuncs.getTree(context.getBuildingInfo, "4", selectedBuildings, alertsDict['1'] || {});
            for (let node of g) {
              if (node.actuator) {
                node.actuator.status = getCurrentStatus(cancelToken, node.actuator.id)?.status
              }
              node.consumption = node.name in gRes.data ? gRes.data[node.name].toFixed(2) + ` ${dUnits[main.channel.input_unit]}` : `0 ${dUnits[main.channel.input_unit]}`
              node.percentage = node.name in gRes.data ? ((gRes.data[node.name] / sum) * 100).toFixed(2) : "0"
            }
            sortHierarchy(g)
            setGHierarchy(g);
            setGHierarchy2(g);
          }).catch(e => {
            if (e.message === 'cancelling') return
            console.log(e)
          })
        } else {
          setGHierarchy([])
        }

        if (context.getBuildingInfo.w_channels.length !== 0) {
          API.getHierarchyConsumptionD(cancelToken, context.getBuildingInfo.building_id, currentDateFormatted, hour, 'Water').then(async wRes => {
            let main = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.w_hierarchy)
            const sum = wRes.data[main.channel_name]
            let w = DiagramFuncs.getTree(context.getBuildingInfo, "5", selectedBuildings, alertsDict['2'] || {});
            for (let node of w) {
              if (node.actuator) {
                node.actuator.status = getCurrentStatus(cancelToken, node.actuator.id)?.status
              }
              node.consumption = node.name in wRes.data ? wRes.data[node.name].toFixed(2) + ` ${dUnits[main.channel.input_unit]}` : `0 ${dUnits[main.channel.input_unit]}`
              node.percentage = node.name in wRes.data ? ((wRes.data[node.name] / sum) * 100).toFixed(2) : "0"
            }
            sortHierarchy(w)
            setWHierarchy(w);
            setWHierarchy2(w);
          }).catch(e => {
            if (e.message === 'cancelling') return
            console.log(e)
          })
        } else {
          setWHierarchy([])
        }
      }).catch(e => {
        if (e.message === 'cancelling') return
        console.log(e)
      })

    }

    getData()

    return () => {
      API.cancel(cancelToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getBuildingInfo, maptype, selectedBuildings])


  const getCurrentStatus = async (cancelToken, actuator) => {
    try {
      let status = await API.getActuatorStatus(cancelToken, actuator)
      return status.data
    } catch (e) {
      if (e.message === 'cancelling') return
      console.log(e)
    }
  }

  useEffect(() => {
    if (!context.getBuildingInfo || !context.getUserInfo) return;

    // Get building options
    let tempBuildingOptions = context.getBuildingInfo.e_channels.map(e => e.building_distribution)
    tempBuildingOptions = tempBuildingOptions.concat(context.getBuildingInfo.w_channels.map(e => e.building_distribution))
    tempBuildingOptions = tempBuildingOptions.concat(context.getBuildingInfo.g_channels.map(e => e.building_distribution))
    tempBuildingOptions = Array.from(new Set(tempBuildingOptions))
    // tempBuildingOptions = tempBuildingOptions.map(e => ({ building: context.getUserInfo.buildings.find(b => b.building_id === e).building, building_id: e }))
    // tempBuildingOptions = tempBuildingOptions.filter(e => e !== null)
    setBuildingOptions(tempBuildingOptions)
    if (selectedBuildings === null) setSelectedBuildings(tempBuildingOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getBuildingInfo, context.getUserInfo]);

  useEffect(() => {
    let tempCircuits = [];
    for (let item in context.nodeSelected) {
      if (context.nodeSelected[item].dataType === 'circuit') {
        let circuit = GraphFuncs.findCirctuitInHierarchy(
          item,
          context.getBuildingInfo.hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.e_sources.filter(e => e.circuit_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'electricity'
        tempCircuits.push(
          circuit
        );
      } else if (context.nodeSelected[item].dataType === 'gas') {
        let circuit = GraphFuncs.findCirctuitInHierarchyD(
          item,
          context.getBuildingInfo.g_hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.g_sources.filter(e => e.channel_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'gas'
        tempCircuits.push(
          circuit
        );
      } else if (context.nodeSelected[item].dataType === 'water') {
        let circuit = GraphFuncs.findCirctuitInHierarchyD(
          item,
          context.getBuildingInfo.w_hierarchy
        )
        if (!circuit) circuit = context.getBuildingInfo.w_sources.filter(e => e.channel_name.trim() === item)[0]
        if (!circuit) continue
        circuit.type = 'water'
        tempCircuits.push(
          circuit
        );
      }
    }
    setCircuits(tempCircuits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.nodeSelected]);


  // Hierarchical tree generator to the selected building
  const selectBuilding = (e) => {
    if (!e.target) return
    let selection = e.target.value
    if (selectedBuildings.includes(selection)) {
      setSelectedBuildings(prev => prev.filter(e => e !== selection))
    } else {
      setSelectedBuildings(prev => [...prev, selection])
    }
  };


  // set sugestions in onChange
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    const filteredSuggestions = circuitOptions.filter(
      (suggestion) =>
        value && suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  // sugestion clicked
  const handleSuggestionClick = (suggestion, type=null) => {
    setInputValue(suggestion);
    setNodePan(suggestion);
    setSuggestions([]);
    DiagramFuncs.selectCircuit({ target: { value: suggestion, name: type ? type : 'circuit' } }, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo)
  };


  const sortHierarchy = (tempClients) => {
    tempClients.sort(function (a, b) {
      var titleA = parseFloat(a.consumption);
      var titleB = parseFloat(b.consumption);
      if (titleA > titleB) {
        return -1;
      }
      if (titleA < titleB) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    let tempNodeSelected = Object.keys(context.nodeSelected)
    if (diagramInstance && diagramInstance.nodes && !clickedNode) {
      if (tempNodeSelected.length > 0) {
        let nodes = diagramInstance.nodes;
        for (let node of nodes) {
          let id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", "").replaceAll("alsdjfklajsdfkjah", " ")
          if (tempNodeSelected[tempNodeSelected.length - 1].includes(id)) {
            setNodePan(node);
          }
        }
      }
    }
    let correctHierarchy = getCorrectHierarchy().hierarchy
    if (correctHierarchy === null) return
    for (let node of correctHierarchy) {
      if (tempNodeSelected.includes(node.id.replaceAll("alsdjfklajsdfkjaherth", ""))) {
        node.selected = true;
      } else {
        node.selected = false;
      }
    }
    setClickedNode(null);
    setCurrentHierarchy(correctHierarchy);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.nodeSelected, maptype, distHierarchy2, egHierarchy2, catHierarchy2, gHierarchy2, wHierarchy2]);

  useEffect(() => {
    if (exporting && diagramInstance) {
      const diagramFullSize = document.getElementById(
        "diagram_diagramLayer_svg"
      );

      let newHeight = diagramFullSize.clientHeight + 500
      let newWidth = diagramFullSize.clientWidth + 500


      const diagramContent = document.getElementById("diagramcontent");
      const toFullList = document.getElementsByClassName("to-full")
      const hidden = document.getElementsByClassName("diagram_hiddenUserHandleTemplate")
      const diagramlayer = document.getElementById('diagram_diagramLayer')
      const diagramBglayer = document.getElementById('diagram_backgroundLayer')

      for (let item of hidden) {
        item.style.height = newHeight + "px";
        item.style.width = newWidth + "px";
      }

      let tempList = []
      for (let item of toFullList) {
        tempList.push([item.clientWidth, item.clientHeight])
        item.style.height = newHeight + "px";
        item.style.width = newWidth + "px";
      }
      setInitList(tempList)
      diagramlayer.style.height = newHeight + "px";
      diagramlayer.style.width = newWidth + "px";

      diagramBglayer.style.height = newHeight + "px";
      diagramBglayer.style.width = newWidth + "px";

      diagramContent.style.height = newHeight + "px";
      diagramContent.style.width = newWidth + "px";

      setDimensions({ width: newWidth, height: newHeight, export: true })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exporting, diagramInstance]);

  const observeDiagramChanges = () => {
    return new Promise((resolve) => {
      let timeout;

      const observer = new MutationObserver(() => {
        clearTimeout(timeout); // Reset the timer on every change

        timeout = setTimeout(() => {
          observer.disconnect(); // Stop observing after no changes for 100ms
          resolve();
        }, 100); // Adjust timeout as needed
      });

      observer.observe(document.getElementById("diagram"), {
        childList: true, // Detect when nodes/connectors update
        subtree: true,   // Watch deep changes within all elements inside
        attributes: true, // Detect position/style updates
      });
    });
  };



  useEffect(() => {
    if (!dimensions.export || !diagramInstance || !initList.length) return
    const exportDiagram = async () => {
      diagramInstance.refresh()
      diagramInstance.reset()
      await observeDiagramChanges()
      const diagramFullSize = document.getElementById("diagramcontent");
      html2canvas(diagramFullSize).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "diagram.png";
        link.click();
        navigate(0);
      })
    }
    exportDiagram()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions, diagramInstance, initList])

  useEffect(() => {
    if (diagramInstance && diagramInstance.nodes) {
      if (nodePan) {
        let nodes = diagramInstance.nodes;
        for (let node of nodes) {
          let id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", '').replaceAll("alsdjfklajsdfkjah", " ");
          if (nodePan === id) {
            setNodePan(node)
            return;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodePan]);

  useEffect(() => {
    if (context.nodeSelected.length > 0 || nodePan) return
    if (!diagramInstance || diagramInstance.nodes.length === 0) setLoaded(!loaded)
    else {
      let bounds = diagramInstance.nodes[0].wrapper.bounds;
      diagramInstance.bringToCenter(bounds);
      diagramInstance.clearSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramInstance])

  useEffect(() => {
    if (!nodePan || !nodePan.wrapper) return
    let bounds = nodePan.wrapper.bounds;
    diagramInstance.bringToCenter(bounds);
    diagramInstance.clearSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodePan])

  const selectNaturalGasUsers = (on) => {
    if (on) {
      let tempArr = [{ target: { name: "", value: "all" } }]
      for (let item of distHierarchy) {
        if (item.naturalGasUser) tempArr.push({ target: { name: "circuit", value: item.id } })
      }
      hanldeMultipleSelect(tempArr)
    }
    else {
      let tempArr = []
      for (let item of distHierarchy) {
        if (item.naturalGasUser && Object.keys(context.nodeSelected).includes(item.id)) tempArr.push({ target: { name: "circuit", value: item.id } })
      }
      hanldeMultipleSelect(tempArr)
    }
  }


  function nodeDefaults(obj, diagram) {
    obj.width = 220;
    obj.height = obj.data.actuator ? 145 : 85;
    obj.bold = true;
    obj.verticalAlignment = "Center";
    obj.internalId = obj.data.id.replaceAll(" ", "alsdjfklajsdfkjah");
    obj.name = obj.data.name;
    obj.dataType = obj.data.dataType;
    obj.consumption = obj.data.consumption;
    obj.device = obj.data.device;
    obj.percentage = obj.data.percentage;
    obj.style = {
      fill: obj.data.selected ? "#5F816D" : "#FFF",
      strokeColor: "none",
      color: "#1D192B",
      strokeWidth: 2,
      textAlign: "Center",
    };
    obj.shape = {
      type: "HTML",
      content: `<div class="cursor-pointer relative" data-internal=${obj.data.id.replaceAll(
        " ",
        "alsdjfklajsdfkjah"
      )} style="background:${obj.data.selected ? "#5F816D" : "#FFF"}; 
      padding: 8px 2px 8px 10px; flex-direction: column;justify-content: center; align-items: flex-start; gap:6px; border-radius: 4px; border-right: 8px ${obj.data.collapsed ? "solid" : "hidden"
        } #3A5345; border-left: 8px solid #3A5345; height:${obj.data.actuator ? '67%' : '100%'}; width:100%; box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);">
          <div class="absolute top-[-8px] left-[-10px] w-[105%] h-[110%]">
            <div class="top-[0] right-[40px] text-right flex flex-nowrap justify-end">
              <div data-context="context" class="w-[15px] p-3 m-3">
              <svg data-context="context" id="fi_2311524" enableBackground="new 0 0 32 32" height="15" viewBox="0 0 32 32" width="15" xmlns="http://www.w3.org/2000/svg"><path id="XMLID_294_" d="m13 16c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path><path id="XMLID_295_" d="m13 26c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path><path id="XMLID_297_" d="m13 6c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z"></path></svg>
              </div>
            </div>
          </div>
          <p style="color: ${obj.data.selected ? "#FFF" : "#000"};text-align:left;  font-size:14px; font-style:normal; font-weight:600; line-height:32px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">${obj.data.name}</p>
          <div class="flex flex-nowrap justify-between items-center">
          <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"}; text-align: left;font-size: 11px;font-style: normal;font-weight: 400;line-height: 20px; /* 181.818% */">previous 24 hours</p>
              ${obj.data.alerts ? '<div class="flex flex-nowrap justify-center items-center px-3">' : ''}
              ${obj.data.alerts && obj.data.alerts['0'] ?
          '<div class="flex flex-nowrap justify-center items-center pl-1"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12" x="0" y="0" viewBox="0 0 512 512"><g><path d="M448 232.148c-11.777 0-21.332-9.554-21.332-21.332 0-59.84-23.297-116.074-65.602-158.402-8.34-8.34-8.34-21.82 0-30.164 8.34-8.34 21.825-8.34 30.164 0 50.372 50.367 78.102 117.336 78.102 188.566 0 11.778-9.555 21.332-21.332 21.332zM21.332 232.148C9.559 232.148 0 222.594 0 210.816 0 139.586 27.734 72.617 78.102 22.25c8.34-8.34 21.824-8.34 30.164 0 8.343 8.344 8.343 21.824 0 30.164C65.96 94.72 42.668 150.977 42.668 210.816c0 11.778-9.559 21.332-21.336 21.332zM434.754 360.813C402.496 333.546 384 293.695 384 251.476V192c0-75.07-55.766-137.215-128-147.625V21.332C256 9.535 246.441 0 234.668 0c-11.777 0-21.336 9.535-21.336 21.332v23.043c-72.254 10.41-128 72.555-128 147.625v59.477c0 42.218-18.496 82.07-50.941 109.503a37.262 37.262 0 0 0-13.059 28.352c0 20.59 16.746 37.336 37.336 37.336h352c20.586 0 37.332-16.746 37.332-37.336 0-10.922-4.758-21.246-13.246-28.52zM234.668 512c38.633 0 70.953-27.543 78.379-64H156.289c7.422 36.457 39.742 64 78.379 64zm0 0" fill="#0538A0" opacity="1" data-original="#000000"></path></g></svg></div>' : ''
        }
              ${obj.data.alerts && obj.data.alerts['1'] ?
          '<div class="flex flex-nowrap justify-center items-center pl-1"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12" x="0" y="0" viewBox="0 0 512 512"><g><path d="M448 232.148c-11.777 0-21.332-9.554-21.332-21.332 0-59.84-23.297-116.074-65.602-158.402-8.34-8.34-8.34-21.82 0-30.164 8.34-8.34 21.825-8.34 30.164 0 50.372 50.367 78.102 117.336 78.102 188.566 0 11.778-9.555 21.332-21.332 21.332zM21.332 232.148C9.559 232.148 0 222.594 0 210.816 0 139.586 27.734 72.617 78.102 22.25c8.34-8.34 21.824-8.34 30.164 0 8.343 8.344 8.343 21.824 0 30.164C65.96 94.72 42.668 150.977 42.668 210.816c0 11.778-9.559 21.332-21.336 21.332zM434.754 360.813C402.496 333.546 384 293.695 384 251.476V192c0-75.07-55.766-137.215-128-147.625V21.332C256 9.535 246.441 0 234.668 0c-11.777 0-21.336 9.535-21.336 21.332v23.043c-72.254 10.41-128 72.555-128 147.625v59.477c0 42.218-18.496 82.07-50.941 109.503a37.262 37.262 0 0 0-13.059 28.352c0 20.59 16.746 37.336 37.336 37.336h352c20.586 0 37.332-16.746 37.332-37.336 0-10.922-4.758-21.246-13.246-28.52zM234.668 512c38.633 0 70.953-27.543 78.379-64H156.289c7.422 36.457 39.742 64 78.379 64zm0 0" fill="#F9941E" opacity="1" data-original="#000000"></path></g></svg></div>' : ''
        }
              ${obj.data.alerts && obj.data.alerts['2'] ?
          '<div class="flex flex-nowrap justify-center items-center pl-1"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="12" height="12" x="0" y="0" viewBox="0 0 512 512"><g><path d="M448 232.148c-11.777 0-21.332-9.554-21.332-21.332 0-59.84-23.297-116.074-65.602-158.402-8.34-8.34-8.34-21.82 0-30.164 8.34-8.34 21.825-8.34 30.164 0 50.372 50.367 78.102 117.336 78.102 188.566 0 11.778-9.555 21.332-21.332 21.332zM21.332 232.148C9.559 232.148 0 222.594 0 210.816 0 139.586 27.734 72.617 78.102 22.25c8.34-8.34 21.824-8.34 30.164 0 8.343 8.344 8.343 21.824 0 30.164C65.96 94.72 42.668 150.977 42.668 210.816c0 11.778-9.559 21.332-21.336 21.332zM434.754 360.813C402.496 333.546 384 293.695 384 251.476V192c0-75.07-55.766-137.215-128-147.625V21.332C256 9.535 246.441 0 234.668 0c-11.777 0-21.336 9.535-21.336 21.332v23.043c-72.254 10.41-128 72.555-128 147.625v59.477c0 42.218-18.496 82.07-50.941 109.503a37.262 37.262 0 0 0-13.059 28.352c0 20.59 16.746 37.336 37.336 37.336h352c20.586 0 37.332-16.746 37.332-37.336 0-10.922-4.758-21.246-13.246-28.52zM234.668 512c38.633 0 70.953-27.543 78.379-64H156.289c7.422 36.457 39.742 64 78.379 64zm0 0" fill="#F75D5F" opacity="1" data-original="#000000"></path></g></svg></div>' : ''
        }          
            ${obj.data.alerts ? '</div>' : ''}        
          </div>
        <div class="flex flex-nowrap justify-between items-center">
        <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"}; text-align: left;font-size: 10px;font-style: normal;font-weight: 600;line-height: 20px; /* 181.818% */">${obj.consumption}</p>
        <p style="color: ${obj.data.selected ? "#FFF" : "#416A53"}; text-align: left;font-size: 10px;font-style: normal;font-weight: 600;line-height: 20px; /* 181.818% */">${isNaN(obj.percentage) || !isFinite(obj.percentage) ? "..." : obj.percentage}% of building</p>
        </div>
        ${obj.data.actuator ?
          `<div class="absolute bottom-[-50%] left-[0px] w-[100%] h-[50%] px-2 py-1 bg-[#e6e6e6] rounded-bl-[50%] rounded-br-md" style="box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);">
          
          <div class="">
          <div class="flex flex-nowrap justify-between items-center">
          <p class="w-1/2" style="color: #416A53; text-align: left;font-size: 11px;font-style: normal;font-weight: 600;line-height: 20px; /* 181.818% */">${obj.data.actuator.name}</p>          
          <div class="flex flex-nowrap justify-end items-end w-full">
          ${obj.data.actuator.status && obj.data.actuator.status === 3 ?
            `<svg width="30" height="17" viewBox="0 0 55 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 5.85556C0 4.71417 0.935102 3.78889 2.08861 3.78889H52.9114C54.0649 3.78889 55 4.71417 55 5.85556V25.1444C55 26.2858 54.0649 27.2111 52.9114 27.2111H2.08861C0.9351 27.2111 0 26.2858 0 25.1444V5.85556Z" fill="#D9D9D9" />
          <path d="M43.1646 15.5C43.1646 24.0604 36.1513 31 27.5 31C18.8487 31 11.8354 24.0604 11.8354 15.5C11.8354 6.93959 18.8487 0 27.5 0C36.1513 0 43.1646 6.93959 43.1646 15.5Z" fill="#D9D9D9" />
          <path d="M0.348101 6.54444H55V24.8H0.348101V6.54444Z" fill="#84CEFF" fill-opacity="0.6" />
          <path d="M38.9873 15.6722C38.9873 21.8547 33.9222 26.8667 27.6741 26.8667C21.4259 26.8667 16.3608 21.8547 16.3608 15.6722C16.3608 9.4897 21.4259 4.47778 27.6741 4.47778C33.9222 4.47778 38.9873 9.4897 38.9873 15.6722Z" fill="#5F5F5F" />
          <path d="M4.87342 15.1556C4.87342 13.4435 6.27607 12.0556 8.00633 12.0556H46.9937C48.7239 12.0556 50.1266 13.4435 50.1266 15.1556C50.1266 16.8676 48.7239 18.2556 46.9937 18.2556H8.00633C6.27607 18.2556 4.87342 16.8676 4.87342 15.1556Z" fill="#5F5F5F" />
          </svg>`: ""
          }
          ${obj.data.actuator.status && obj.data.actuator.status === 1 ?
            `<svg width="30" height="17" viewBox="0 0 55 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 7.55516C0 6.396 0.935102 5.45631 2.08861 5.45631H52.9114C54.0649 5.45631 55 6.396 55 7.55516V27.1445C55 28.3036 54.0649 29.2433 52.9114 29.2433H2.08861C0.9351 29.2433 0 28.3036 0 27.1445V7.55516Z" fill="#D9D9D9" />
          <path d="M43.1646 17.3498C43.1646 26.0435 36.1513 33.0912 27.5 33.0912C18.8487 33.0912 11.8354 26.0435 11.8354 17.3498C11.8354 8.65608 18.8487 1.60841 27.5 1.60841C36.1513 1.60841 43.1646 8.65608 43.1646 17.3498Z" fill="#D9D9D9" />
          <path d="M0.348101 8.25478H27.8481V26.7946H0.348101V8.25478Z" fill="#84CEFF" fill-opacity="0.6" />
          <path d="M27.1519 13.5019H54.6519V21.5475H27.1519V13.5019Z" fill="#84CEFF" fill-opacity="0.6" />
          <path d="M38.9873 17.5247C38.9873 23.8035 33.9222 28.8935 27.6741 28.8935C21.4259 28.8935 16.3608 23.8035 16.3608 17.5247C16.3608 11.2459 21.4259 6.15593 27.6741 6.15593C33.9222 6.15593 38.9873 11.2459 38.9873 17.5247Z" fill="#5F5F5F" />
          <path d="M11.5006 33.0779C10.2771 31.8484 10.2771 29.855 11.5006 28.6256L39.0688 0.92211C40.2923 -0.30737 42.2759 -0.30737 43.4994 0.922109C44.7229 2.15159 44.7229 4.14497 43.4994 5.37445L15.9312 33.0779C14.7077 34.3074 12.7241 34.3074 11.5006 33.0779Z" fill="#5F5F5F" />
          </svg>`: ""
          }
          ${obj.data.actuator.status && obj.data.actuator.status === 0 ?
            `<svg width="30" height="17" viewBox="0 0 55 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 13.1538C0 12.0068 0.935102 11.0769 2.08861 11.0769H52.9114C54.0649 11.0769 55 12.0068 55 13.1538V32.5385C55 33.6855 54.0649 34.6154 52.9114 34.6154H2.08861C0.9351 34.6154 0 33.6855 0 32.5385V13.1538Z" fill="#D9D9D9" />
                <path d="M43.1646 22.8462C43.1646 31.4491 36.1513 38.4231 27.5 38.4231C18.8487 38.4231 11.8354 31.4491 11.8354 22.8462C11.8354 14.2433 18.8487 7.26923 27.5 7.26923C36.1513 7.26923 43.1646 14.2433 43.1646 22.8462Z" fill="#D9D9D9" />
                <path d="M0.348101 13.8462H24.3671V32.1923H0.348101V13.8462Z" fill="#84CEFF" fill-opacity="0.6" />
                <path d="M38.9873 23.0192C38.9873 29.2324 33.9222 34.2692 27.6741 34.2692C21.4259 34.2692 16.3608 29.2324 16.3608 23.0192C16.3608 16.806 21.4259 11.7692 27.6741 11.7692C33.9222 11.7692 38.9873 16.806 38.9873 23.0192Z" fill="#5F5F5F" />
                <path d="M27.5 45C25.7697 45 24.3671 43.6052 24.3671 41.8846V3.11539C24.3671 1.39481 25.7697 0 27.5 0C29.2303 0 30.6329 1.39481 30.6329 3.11538V41.8846C30.6329 43.6052 29.2303 45 27.5 45Z" fill="#5F5F5F" />
                </svg>`: ""
          }
          ${`<div class="ml-2">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 48 48" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><rect width="34" height="29" x="7" y="15" fill=${obj.data.actuator.auto_enabled ? "#3A5345" : "#eff3f9"} rx="4" opacity="1" data-original="#eff3f9" class=""></rect>
          <path fill=${obj.data.actuator.auto_enabled ? "#3A5345" : "#f6f6f6"} d="M11 38V15a4 4 0 0 0-4 4v21a4 4 0 0 0 4 4h26a4 4 0 0 0 4-4H13a2 2 0 0 1-2-2z" opacity="1" data-original="#cfe0f3" class=""></path><circle cx="18" cy="25" r="2" fill="#e0e0e0" opacity="1" data-original="#afb4c2" class=""></circle><circle cx="30" cy="25" r="2" fill="#e0e0e0" opacity="1" data-original="#afb4c2" class=""></circle>
          <path fill=${obj.data.actuator.auto_enabled ? "#3A5345" : "#c1c1c1"} d="M45 36h-4V23h4a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2zM3 36h4V23H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2z" opacity="1" data-original="#8c94af" class=""></path>
          <path fill=${obj.data.actuator.auto_enabled ? "#3A5345" : "#c1c1c1"} d="M45 32h-4v4h4a2 2 0 0 0 2-2v-4a2 2 0 0 1-2 2zM1 30v4a2 2 0 0 0 2 2h4v-4H3a2 2 0 0 1-2-2z" opacity="1" data-original="#6f7792" class=""></path><circle cx="24" cy="6" r="3" fill="#c1c1c1" opacity="1" data-original="#ff3b65" class=""></circle>
          <path fill=${obj.data.actuator.auto_enabled ? "#3A5345" : "#c1c1c1"} d="M25.5 7A2.5 2.5 0 0 1 23 4.5c0-.54.175-1.037.467-1.446A2.995 2.995 0 0 0 24 9a2.995 2.995 0 0 0 2.946-2.467A2.485 2.485 0 0 1 25.5 7z" opacity="0.4588235294117647" data-original="#d82b5075" class=""></path><g fill="#08105e">
          <path d="M21 25c0-1.654-1.346-3-3-3s-3 1.346-3 3 1.346 3 3 3 3-1.346 3-3zm-3 1a1.001 1.001 0 0 1 0-2c.551 0 1 .449 1 1s-.449 1-1 1zM30 22c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4a1.001 1.001 0 0 1 0-2c.551 0 1 .449 1 1s-.449 1-1 1zM28.543 33.91c-2.815 1.447-6.271 1.447-9.086 0a1 1 0 1 0-.914 1.78c1.669.857 3.556 1.311 5.457 1.311s3.788-.453 5.457-1.311a1.001 1.001 0 0 0-.914-1.78z" fill="#a1a1a1" opacity="1" data-original="#08105e" class=""></path>
          <path d="M45 22h-3v-3c0-2.757-2.243-5-5-5H25V9.858c1.72-.447 3-2 3-3.858 0-2.206-1.794-4-4-4s-4 1.794-4 4c0 1.858 1.28 3.411 3 3.858V14H11c-2.757 0-5 2.243-5 5v3H3c-1.654 0-3 1.346-3 3v9c0 1.654 1.346 3 3 3h3v3c0 2.757 2.243 5 5 5h26c2.757 0 5-2.243 5-5v-3h3c1.654 0 3-1.346 3-3v-9c0-1.654-1.346-3-3-3zM22 6c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zM3 35c-.551 0-1-.448-1-1v-9c0-.551.449-1 1-1h3v11zm37 5c0 1.654-1.346 3-3 3H11c-1.654 0-3-1.346-3-3V19c0-1.654 1.346-3 3-3h26c1.654 0 3 1.346 3 3zm6-6c0 .552-.449 1-1 1h-3V24h3c.551 0 1 .449 1 1z" fill="#a1a1a1" opacity="1" data-original="#08105e" class=""></path></g></g></svg>
          </div>`
          }
          </div>
          </div>`
          :
          ""
        }
      </div>
      `,
    };
    obj.backgroundColor = "#FFF";
    obj.borderWidth = 1;
    obj.borderColor = "#FFF";
    obj.constraints =
      NodeConstraints.Default &
      ~NodeConstraints.Drag &
      ~NodeConstraints.Delete &
      ~NodeConstraints.Rotate &
      ~NodeConstraints.AspectRatio &
      ~NodeConstraints.Expandable &
      ~NodeConstraints.Tooltip &
      ~NodeConstraints.Resize;
    return obj;
  }


  //sets connector default value
  function connectorDefaults(connector, diagram) {
    connector.style = {
      strokeColor: "#B8B8B8",
      fill: "#B8B8B8",
      strokeWidth: 2,
    };
    connector.targetDecorator = {
      style: {
        fill: "#B8B8B8",
        strokeColor: "#B8B8B8",
      },
    };
    connector.type = "Orthogonal";
    connector.constraints = 1;
    connector.cornerRadius = 5;
    return connector;
  }

  function tooledit(args) {
    switch (args.item.tooltipText) {
      case "Zoom In":
        let zoomin = { type: "ZoomIn", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomin);
        break;
      case "Zoom Out":
        let zoomout = { type: "ZoomOut", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomout);
        break;
      case "Select":
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {};
        diagramInstance.tool = DiagramTools.SingleSelect;
        break;
      case "Pan Tool":
        diagramInstance.clearSelection();
        diagramInstance.drawingObject = {};
        diagramInstance.tool = DiagramTools.ZoomPan;
        break;
      case "Maximize":
        setExpanded(!expanded);
        break;
      case "Minimize":
        setExpanded(!expanded);
        break;
      default:
        break;
      case "Reset":
        diagramInstance.reset();
        break;
      case "Download":
        setExporting(true);
        diagramInstance.reset();
        break;
      case "Print":
        diagramInstance.print({ mode: "SVG" });
        break;
      case "Expand All":
        expandNode('all')
        setCollapseAll(!collapseAll)
        break;
      case "Collapse All":
        collapseNode('all')
        setCollapseAll(!collapseAll)
        break;
    }
  }

  function updateCircuits(tree) {
    if (tree.length > 0) {
      var auxOptionsCircuit = [];
      for (let circ of tree) {
        auxOptionsCircuit.push(circ.name);
      }
      setCircuitOptions(auxOptionsCircuit);
    }
  }

  const getCorrectHierarchy = () => {
    switch (maptype) {
      case "1": default:
        return { name: "distHierarchy", hierarchy: JSON.parse(JSON.stringify(distHierarchy2)), setter: setDistHierarchy2, oHierarchy: JSON.parse(JSON.stringify(distHierarchy)) }
      case "2":
        return { name: "egHierarchy", hierarchy: JSON.parse(JSON.stringify(egHierarchy2)), setter: setEgHierarchy2, oHierarchy: JSON.parse(JSON.stringify(egHierarchy)) }
      case "3":
        return { name: "catHierarchy", hierarchy: JSON.parse(JSON.stringify(catHierarchy2)), setter: setCatHierarchy2, oHierarchy: JSON.parse(JSON.stringify(catHierarchy)) }
      case "4":
        return { name: "gHierarchy", hierarchy: JSON.parse(JSON.stringify(gHierarchy2)), setter: setGHierarchy2, oHierarchy: JSON.parse(JSON.stringify(gHierarchy)) }
      case "5":
        return { name: "wHierarchy", hierarchy: JSON.parse(JSON.stringify(wHierarchy2)), setter: setWHierarchy2, oHierarchy: JSON.parse(JSON.stringify(wHierarchy)) }
    }
  }

  const handleContextMenuSelect = (e) => {
    setPositionContextMenu({
      x: e.clientX + "px",
      y: e.clientY + "px",
    });
    setShowContextMenu(true);
  };

  const handleClick = useCallback(() => {
    if (!diagramInstance || showContextMenu) return;

    const selectedItems = diagramInstance.selectedItems.nodes;
    if (selectedItems.length > 0) {
      const node = selectedItems[0];
      if (!node) return;
      const id = node.internalId.replaceAll("alsdjfklajsdfkjaherth", "").replaceAll("alsdjfklajsdfkjah", " ");
      DiagramFuncs.selectCircuit(
        { target: { name: node.dataType, value: id } },
        context.nodeSelected,
        context.setNodeSelected,
        context.getBuildingInfo
      );
    }
  }, [diagramInstance, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo, showContextMenu]);

  const handleContext = useCallback((event) => {
    if (!diagramInstance) return;
    const selectedItems = diagramInstance.selectedItems.nodes;
    if (
      diagramInstance &&
      diagramInstance.selectedItems
    ) {
      if (selectedItems[0]) {
        handleContextMenuSelect(event);
        setClickedNode(selectedItems[0])
      }
    }
  }, [diagramInstance]);


  useEffect(() => {
    if (!diagramInstance) return
    const handleMouseUp = (event) => {
      event.preventDefault();
      if (event.target.dataset.context) {
        handleContext(event);
      } else {
        handleClick();
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramInstance, handleClick])

  useEffect(() => {
    if (!context.getBuildingInfo || !diagramInstance || paramsSet) return
    let channel = queryParams.get('channel')
    let start = queryParams.get('start')
    let end = queryParams.get('end')
    let type = queryParams.get('type')
    if (!channel || !start || !end || !type) return
    const typeDict = {"0": null, "1": 'gas', "2": "water"}
    handleSuggestionClick(channel, typeDict[type] || null)
    setParamsSet(true)
    setStartEnd([start, end])
    const mapTypeDict = { '0': "1", '1': "4", '2': "5" }
    setMapType(mapTypeDict[type])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, context.getBuildingInfo, paramsSet, diagramInstance])


  useEffect(() => {
    if (!getCorrectHierarchy().hierarchy) return
    if (collapseAll) {
      collapseNode('all')
    } else {
      expandNode('all')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseAll])

  const collapseNode = (node) => {
    var correctHierarchy = getCorrectHierarchy()
    var tempHierarchy = correctHierarchy.hierarchy;

    var nodesToDelete = [];
    if (node !== 'all') {
      DiagramFuncs.getChildren(tempHierarchy, node.data.id, nodesToDelete);
      tempHierarchy = tempHierarchy.filter(
        (e) => !nodesToDelete.includes(e.id)
      );
      for (let e of tempHierarchy) {
        if (e.id === node.data.id) e.collapsed = true
      }
    } else {
      tempHierarchy = tempHierarchy.filter(
        (e) => e.parent === null
      );
      for (let e of tempHierarchy) {
        e.collapsed = true
        node = JSON.parse(JSON.stringify(hierarchyDict[correctHierarchy.name]))
      }
    }
    correctHierarchy.setter(tempHierarchy);
    updateCircuits(tempHierarchy);
    setNodePan(node)
    return;
  };

  const expandNode = (node) => {
    var correctHierarchy = getCorrectHierarchy()
    var tempHierarchy = correctHierarchy.hierarchy;

    var nodesToPush = [];
    if (node !== 'all') {
      DiagramFuncs.getImmediateChildren(hierarchyDictO[correctHierarchy.name], node.data.id, nodesToPush);
      for (let n of hierarchyDictO[correctHierarchy.name]) {
        if (nodesToPush.includes(n.id)) {
          n.collapsed = true
          tempHierarchy.push(n);
        }
      }
      for (let e of tempHierarchy) {
        if (e.id === node.data.id) e.collapsed = false
      }
    } else {
      tempHierarchy = []
      for (let n of hierarchyDictO[correctHierarchy.name]) {
        node = JSON.parse(JSON.stringify(hierarchyDictO[correctHierarchy.name]))
        n.collapsed = false
        tempHierarchy.push(n);
      }
    }
    correctHierarchy.setter(tempHierarchy);
    updateCircuits(tempHierarchy);
    setNodePan(node)
    return;
  };


  const hanldeMultipleSelect = (elem) => {
    DiagramFuncs.selectCircuit(elem, context.nodeSelected, context.setNodeSelected, context.getBuildingInfo)
  }


  return (
    <>
      {/* Search Bar */}
      <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-end items-center rounded-md bg-[transparent] shadow-md">
        <div className="flex flex-nowrap justify-end items-center w-11/12 gap-3">
          <p className="text-[black] w-[70px]">View By: </p>
          {!context.getLaptop && <div className="w-[200px] flex items-center h-[36px] mt-[2px] justify-center border border-1 rounded border-[#C1C1C1] self-stretch">
            <div className="flex itens-center pl-2 justify-center self-center">
              {GlobalSVG.advancedFilter()}
            </div>
            <select
              id="distributions"
              className="border-none w-[200px] h-[33px] bg-[transparent] text-normal text-[grey] leading-4"
              defaultValue={maptype}
              onChange={(e) => setMapType(e.currentTarget.value)}
            >
              <option className="text-[#000000]" value="1">Distribution</option>
              <option className="text-[#000000]" value="3">Categories</option>
              <option className="text-[#000000]" value="2">Equipment Groups</option>
              {context.getBuildingInfo && context.getBuildingInfo.g_channels.length > 0 && <option className="text-[#000000]" value="4">Gas</option>}
              {context.getBuildingInfo && context.getBuildingInfo.w_channels.length > 0 && <option className="text-[#000000]" value="5">Water</option>}
            </select>
          </div>}
          {
            !context.getLaptop && buildingOptions.length > 0 && <MultipleSelect data={buildingOptions} dataType='building' selectItem={selectBuilding} selectedList={selectedBuildings} placeholder="Filter Buildings" idAttr="building" valAttr="building" />
          }
          {
            !context.getLaptop && maptype === "2" && circuitOptions.length > 0 && <MultipleSelect data={eGroupOptions} dataType='equipment_group' selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Equipment Groups" idAttr="eg_id" valAttr="eg" />
          }
          {
            !context.getLaptop && maptype === "3" && circuitOptions.length > 0 && <MultipleSelect data={categoryOptions} dataType='category' selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Categories" />
          }
          {
            !context.getLaptop && circuitOptions.length > 0 && <MultipleSelect data={circuitOptions} dataType={typeDict[maptype]} selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Circuits" />
          }
          <div className="relative h-10 flex justify-center items-center gap-3 w-[250px]">
            <div className="relative flex flex-nowrap h-9 p-2.5 gap-2.5 items-center rounded-md border-[1px] border-[#C1C1C1]">
              <input
                type="search"
                className="border-none h-[33px] text-[#AFAAB1] text-base text-normal leading-4 h-9 border-0 bg-[transparent] px-2 placeholder-[grey]"
                placeholder="Search"
                aria-label="Search"
                value={inputValue}
                onChange={handleInputChange}
              />
              <span className="input-group-text flex items-center rounded py-1.5">
                {GlobalSVG.search()}
              </span>
            </div>
            {suggestions.length > 0 && (
              <div
                className={`absolute top-[94%] w-full h-[250px] overflow-auto flex flex-col px-1 py-4 whitespace-nowrap border border-[#C1C1C1] z-10 rounded bg-[#FFF]`}
              >
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li
                      className="cursor-pointer p-1 mx-1"
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {context.getLaptop &&
          <div className="input-group-text flex items-center whitespace-nowrap cursor-pointer" onClick={() => setOpenFilters(!openFilters)}>
            {GlobalSVG.menuExport()}
            <p className="text-sm text-normal leading-4">Filter</p>
          </div>}
      </div>


      {context.getLaptop && <div className={`${openFilters ? 'tablet:w-[300px] mobile:w-full p-3' : "w-0"} card alert-sidebar transition-width duration-300 ease-in-out h-full fixed right-0 tablet:top-[10] mobile:top-[10] z-40 background-white`}>
        {context.getLaptop && openFilters &&
          <div className="flex flex-nowrap justify-center items-center my-4">
            <div className="flex w-[200px] items-center p-1 my-1 justify-center border border-1 rounded bg-[#619E7B] border-[#C1C1C1] self-stretch">
              <div className="flex items-center pl-2 justify-center self-center">
                {GlobalSVG.advancedFilter()}
              </div>
              <select
                id="distributions"
                className="border-none h-[33px] w-61 bg-[transparent] text-normal text-[grey] leading-4"
                defaultValue={maptype}
                onChange={(e) => setMapType(e.currentTarget.value)}
              >
                <option className="text-[#000000]" value="1">Distribution</option>
                <option className="text-[#000000]" value="3">Categories</option>
                <option className="text-[#000000]" value="2">Equipment Groups</option>
                <option className="text-[#000000]" value="4">Gas</option>
                <option className="text-[#000000]" value="5">Water</option>
              </select>
            </div>
          </div>
        }
        {
          context.getLaptop && openFilters && buildingOptions.length > 0 && <MultipleSelect data={buildingOptions} dataType='building' selectItem={selectBuilding} selectedList={selectedBuildings} placeholder="Filter Buildings" idAttr="building" valAttr="building" />
        }
        {
          context.getLaptop && openFilters && maptype === "2" && circuitOptions.length > 0 && <MultipleSelect data={eGroupOptions} dataType='equipment_group' selectItem={hanldeMultipleSelect} selectedList={context.nodeSelected} placeholder="Select Equipment Groups" idAttr="eg_id" valAttr="eg" />
        }
        {
          context.getLaptop && openFilters && maptype === "3" && circuitOptions.length > 0 && <MultipleSelect data={categoryOptions} dataType='category' selectItem={hanldeMultipleSelect} selectedList={context.nodeSelected} placeholder="Select Categories" />
        }
        {
          context.getLaptop && openFilters && circuitOptions.length > 0 && <MultipleSelect data={circuitOptions} dataType={typeDict[maptype]} selectItem={hanldeMultipleSelect} selectedList={Object.keys(context.nodeSelected)} placeholder="Select Circuits" />
        }
      </div>}

      <div className={`flex flex-1 laptop:p-8 laptop:pb-3 w-full ${expanded || !context.nodeSelected || Object.keys(context.nodeSelected).length === 0 ? "h-[726px]" : "h-[564px]"} justify-center items-center self-stretch bg-[transparent]`} >
        {distHierarchy && egHierarchy && catHierarchy && wHierarchy && gHierarchy ? (
          <div className="relative flex flex-1 w-full h-full px-8 pt-6 pb-2.5 justify-center items-center gap-16 self-stretch rounded-lg bg-[transparent] shadow-md to-full">
            <div className="w-full bg-[transparent] to-full">
              <div className="col-lg-12 control-section bg-[transparent] to-full">
                <div className="content-wrapper bg-[transparent] to-full" >
                  <div id={"container-diagram"} className="bg-[transparent] to-full">
                    <DiagramComponent
                      id="diagram"
                      ref={(diagram) => (setDiagramInstance(diagram))}
                      snapSettings={{ constraints: SnapConstraints.SnapToObject }}
                      height={dimensions.height}
                      width={dimensions.width}
                      created={() => {

                      }}
                      layout={{
                        type: "HierarchicalTree",
                        margin: {
                          top: 10,
                          bottom: 10,
                        },
                        orientation: "LeftToRight",
                        verticalSpacing: 100,
                        horizontalSpacing: 10,
                        verticalAlignment: "Center",
                      }}
                      tool={DiagramTools.SingleSelect}
                      dataSourceSettings={{
                        id: "id",
                        parentId: "parent",
                        dataSource: new DataManager(currentHierarchy),
                      }}
                      getNodeDefaults={(node, diagram) => {
                        return nodeDefaults(node, diagram);
                      }}
                      getConnectorDefaults={(connector, diagram) => {
                        return connectorDefaults(connector, diagram);
                      }}
                      mode="SVG"
                    >
                      <Inject
                        services={[DataBinding, HierarchicalTree, PrintAndExport]}
                      />
                    </DiagramComponent>
                  </div>
                </div>
              </div>

              <div className="w-full col-lg-4 property-section">
                <div className="property-panel-header"></div>

                <div
                  className="row w-full property-panel-content"
                  style={{ paddingTop: "10px" }}
                ></div>
              </div>
            </div>
            {showContextMenu && (
              <ContextMenu
                setShow={setShowContextMenu}
                node={clickedNode}
                setClickedNode={setClickedNode}
                position={positionContextMenu}
                collapseNode={collapseNode}
                expandNode={expandNode}
              ></ContextMenu>
            )}
            <div className="absolute bottom-[30px] right-[0px] inline-flex w-8 flex-col items-center p-2 gap-1 shrink rounded bg-[transparent] shadow">
              <ToolbarComponent id="toolbar_diagram" clicked={tooledit}>
                <ItemsDirective>
                  <ItemDirective
                    prefixIcon="e-icons e-download"
                    tooltipText="Download"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-zoom-in"
                    tooltipText="Zoom In"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-zoom-out"
                    tooltipText="Zoom Out"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-mouse-pointer"
                    tooltipText="Select"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-pan"
                    tooltipText="Pan Tool"
                  />
                  <ItemDirective
                    prefixIcon="e-icons e-reset"
                    tooltipText="Reset"
                  />
                  <ItemDirective
                    prefixIcon={`e-icons ${expanded ? "e-align-top" : "e-align-bottom"}`}
                    tooltipText={expanded ? "Minimize" : "Maximize"}
                  />
                  <ItemDirective
                    prefixIcon={`e-icons ${collapseAll ? "e-full-screen" : "e-exit-full-screen"}`}
                    tooltipText={collapseAll ? 'Expand All' : 'Collapse All'}
                  />
                </ItemsDirective>
              </ToolbarComponent>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <p className="laptop:pl-8 text-sm">(*) Virtual Circuit</p>
      {/* Distributions Analisys */}
      {circuits && circuits.length > 0 && (
        <div className='laptop:mx-8 laptop:mb-8 tablet:mb-4 mobile:my-4'>
          <MainGraph
            circuits={circuits ? circuits : []}
            startEnd={startEnd}
            dist={true}
            showAlerts={paramsSet}
            selectNaturalGasUsers={selectNaturalGasUsers} />
        </div>
      )}
    </>
  );
};

export default Distribuition;
