import { useContext, useEffect, useState } from "react"
import { AppContext } from '../../utils/ContextProvider'
import API from "../../utils/API"
import Swal from 'sweetalert2'
import GlobalSVG from "../../utils/GlobalSVG"
import { Link } from "react-router-dom"
import moment from 'moment'
import CircuitParamLine from "../CircuitParamLine"
import LoaderWheel from "../LoaderWheel"

const AlertSubLogLine = (props) => {

    const context = useContext(AppContext)
    const [saving, setSaving] = useState(false)
    const [closing, setClosing] = useState(false)
    const scheduleTypeDict = { "0": 'Custom', "1": 'Operating Hours', "2": 'Off-Hours' }
    const [sublog, setSubLog] = useState(null)
    const months1 = [{ "0": 'JAN' }, { "1": 'FEB' }, { "2": 'MAR' }, { "3": 'APR' }, { "4": 'MAY' }, { "5": 'JUN' }]
    const months2 = [{ "6": 'JUL' }, { "7": 'AUG' }, { "8": 'SEP' }, { "9": 'OCT' }, { "10": 'NOV' }, { "11": 'DEC' }, { "all": "All" }]
    const days1 = [{ "0": 'MON' }, { "1": 'TUE' }, { "2": 'WED' }, { "3": 'THU' }]
    const days2 = [{ "4": 'FRI' }, { "5": 'SAT' }, { "6": 'SUN' }, { "all": "All" }]
    const hours1 = [{ "0": '00' }, { "1": '01' }, { "2": '02' }, { "3": '02' }, { "4": '04' }, { "5": '05' }, { "6": '06' },
    { "7": '07' }, { "8": '08' }, { "9": '09' }, { "10": '10' }, { "11": '11' }]
    const hours2 = [{ "12": '12' }, { "13": '13' }, { "14": '14' }, { "15": '15' }, { "16": '16' }, { "17": '17' }, { "18": '18' },
    { "19": '19' }, { "20": '20' }, { "21": '21' }, { "22": '22' }, { "23": '23' }, { "all": "All" }]

    useEffect(() => {
        if (!props.sublog) return
        let tempLog = JSON.parse(JSON.stringify(props.sublog))
        tempLog.parameters.forEach(p => {
            if (p.type === 'circuit') {
                p.value = JSON.parse(p.value)
            }
        })
        setSubLog(tempLog)
    }, [props.sublog])

    const handleClose = (type) => {
        Swal.fire({
            title: 'Close Alert.',
            text: `Are you sure you want to close this alert?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.updateSubLog({ false_alert: type }, sublog.id).then(res => {
                    setSubLog(s=> ({...s, closed_at: res.data.closed_at, closed_by: res.data.closed_by}))
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setClosing(false)
                })

            } else {
                setSaving(false)
                setClosing(false)
            }
        })
    }

    return (
        <div>
            {sublog &&
                <div className='flex flex-nowrap justify-between items-center p-4 my-2 w-full border-b border-[#B8BBB9] relative'>
                    <div className="mr-[20px] w-[90px] ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Triggered At</p>
                        {context.getBuildingInfo && sublog.timestamp && sublog.channel_id && props.log.channel_type !== 3 && sublog.point_name ?
                            <Link to={`/distribution?building=${context.getBuildingInfo.building_id}&start=${moment(sublog.timestamp).format('YYYY-MM-DD')}&end=${moment(sublog.timestamp).format('YYYY-MM-DD')}&channel=${encodeURIComponent(sublog.point_name)}&type=${props.log.channel_type}`} target="_blank">
                                <div className={`underline w-[120px] text-[12px] ${sublog.timestamp ? "" : "pl-[25px]"}`}>{sublog.timestamp ? moment(sublog.timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                            </Link>
                            :
                            <div className={`w-[120px] text-[12px] ${sublog.timestamp ? "" : "pl-[25px]"}`}>{sublog.timestamp ? moment(sublog.timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                        }
                    </div>
                    <div className="mr-[20px] w-[90px] ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Schedule Type</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {[0, 1, 2].includes(sublog.schedule_type) ? scheduleTypeDict[sublog.schedule_type] : " - "}
                        </div>
                    </div>
                    {props.log.group_parameters.filter(e => e.type !== 'circuit').map((groupParam, idx) => (
                        <div key={'param' + idx} className="mr-[20px] w-1/12 ">
                            <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{groupParam.display_name}</p>
                            <div
                                className='text-xs input-color outline-none'>
                                {sublog.parameters && (sublog.parameters.find(logParam => logParam.alert_param_type_id === groupParam.id) || { value: null }).value}
                            </div>
                        </div>
                    ))}
                    <div className="mr-[20px] w-[90px] ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Closed By</p>
                        <div className={`w-[100px] text-[12px]`}>{sublog.closed_by && sublog.closed_by.trim() !== '' ? sublog.closed_by : " - "}</div>
                    </div>
                    <div className="mr-[20px] w-[90px] ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Closed At</p>
                        <div className={`w-[120px] text-[12px]`}>{sublog.closed_at ? moment(sublog.closed_at).format('MMM D, YYYY h:mma') : " - "}</div>
                    </div>
                    <div className="mr-[20px] w-[90px] ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>False Alert</p>
                        <div className={`w-[120px] text-[12px]`}>{sublog.false_alert !== null  ? (sublog.false_alert ? "Yes" : "No") : " - "}</div>
                    </div>
                    {context.getUserInfo && context.getUserInfo.manage_alert_sub_logs && (!saving ?
                        <div className="flex flex-nowrap items-center justify-end">
                            <div onClick={() => setClosing(!closing)} className="relative cursor-pointer">
                                {GlobalSVG.check2()}
                                {closing &&
                                    <div className={`rounded-md absolute top-[-20px] right-[24px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                        <div className="flex flex-nowrap rounded-md">
                                            <div className="flex flex-nowrap justify-center items-center p-2 text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(true)} >
                                                {GlobalSVG.false()}
                                                <div className="ml-2 mt-1">
                                                    False Alert
                                                </div>
                                            </div>
                                            <div className="flex flex-row p-2 border-l border-[#B8BBB9] text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(false)} >
                                                {GlobalSVG.check2()}
                                                <div className="ml-2 mt-1">
                                                    Complete
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <input className="ml-[10px]" type="checkbox" name={sublog.id} id="" checked={props.selectedList && props.selectedList.includes(sublog.id)} onChange={props.handleSelect} />
                        </div>
                        :
                        <LoaderWheel />)
                    }
                </div>
            }
            {
                sublog && sublog.schedule_type === 0 &&
                <div className="flex flex-nowrap items-center justify-between p-[20px] mb-[10px]">
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Months</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                months1.map(e => (
                                    <div key={'months1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.months && sublog.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                months2.map(e => (
                                    <div key={'months2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.months && sublog.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Days</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                days1.map(e => (
                                    <div key={'days1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.days && sublog.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                days2.map(e => (
                                    <div key={'days2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.days && sublog.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Hours</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                hours1.map(e => (
                                    <div key={'hours1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.hours && sublog.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                hours2.map(e => (
                                    <div key={'hours2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${sublog.hours && sublog.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
            {
                sublog && props.equipmentList &&
                sublog.parameters && sublog.parameters.filter(e => e.type === 'circuit').map((param, idx) => (
                    <CircuitParamLine
                        key={'paramcircuitlog' + idx}
                        param={param}
                        idx={idx}
                        edit={false}
                        activity={sublog}
                        equipmentList={props.equipmentList}
                    />
                ))
            }

        </div >
    )
}
export default AlertSubLogLine